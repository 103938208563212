import {Component} from '@angular/core';
import {ActivatedRoute} from '@angular/router';

@Component({
  selector: 'transacoes',
  templateUrl: './manual.component.html'
})

export class ManualComponent {
  tipo = '0';

  private sub: any;

  constructor(private route: ActivatedRoute) {
    this.sub = this.route.params.subscribe(params => {
      this.tipo = params['tipo'];
    });
  }
}
