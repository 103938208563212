export class DualList {
  static format = {
    add: "Adicionar selecionado(s)",
    remove: "Remover selecionado(s)",
    all: "Selecionar todos",
    none: "Remover todos",
    direction: "left-to-right",
    draggable: true,
    locale: "pt-br",
  };

  static idToObject(
    arrWithIds: any[],
    arrToFind: any[],
    finalArray: any[],
    idKeyToFind: string,
    idKeyInFinalArr: string = idKeyToFind
  ) {
    arrWithIds.forEach((element) => {
      let elementPushed: any;
      elementPushed = arrToFind.find(
        (item) => item[idKeyToFind] == element[idKeyInFinalArr]
      );
      if (elementPushed) finalArray.push(elementPushed);
    });
  }

  static objectToId(arr: any[], objectIdKey: string): any[] {
    let arrWithIds = [];
    arr.forEach((element) => {
      arrWithIds.push(element[objectIdKey]);
    });

    return arrWithIds;
  }
}
