import {
  Component,
  Input,
  OnChanges,
  SimpleChange,
  Output,
  EventEmitter,
} from "@angular/core";
import { RegraService } from "../../regras.service";
import { UserService } from "../../../../shared/services/user.service";
import Swal from "sweetalert2";

@Component({
  selector: "pesquisar-regras-perfil",
  templateUrl: "./pesquisar-perfil-regras.component.html",
})
export class PesquisarPerfilRegrasComponent implements OnChanges {
  filtro: any;
  arrDados: any;
  ordenacao: any;
  conteudoCarregado = false;

  @Input() evento: number;
  @Input() idcliente = "0";
  @Output() carregado = new EventEmitter<boolean>();
  @Output() editar = new EventEmitter<string>();
  @Output() editarRegras = new EventEmitter<string>();
  @Output() visualizar = new EventEmitter<string>();

  centrocusto = 0;

  alterarDados = true;

  constructor(
    private regraService: RegraService,
    private userService: UserService
  ) {
    this.centrocusto = this.userService.usuario.centrocusto;

    this.carregado.emit(false);

    this.filtro = {
      paginaAtual: 1,
      paginaFinal: 1,
      quantidade: 0,
      limite: "10",
      ordenacao: "cnome",
      sorting: "asc",
    };

    this.ordenacao = [
      { estado: 2, campo: "cnome" },
      { estado: 2, campo: "lativo" },
    ];

    this.arrDados = [];

    this.alterarDados = this.userService.alterarDados;
  }

  excluir(id: string): void {
    Swal.fire({
      title: "Deletar Perfil de Regras?",
      text: "Você está prestes a deletar o perfil de regras",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sim, delete!",
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return this.regraService.deletarPerfilRegras({
          id: id,
          idcliente: this.idcliente,
        });
      },
    }).then((result) => {
      if (result.isConfirmed) {
        if (result.value.success) {
          Swal.fire({
            title: "",
            text: "Perfil de regras deletado com sucesso!",
            icon: "success",
            showCancelButton: false,
          }).then(() => {
            this.filtro.paginaAtual = 1;
            this.filtro.quantidade = 0;
            this.filtrarDataGridLocal(this.filtro);
          });
        } else {
          Swal.fire("", "Erro ao atualizar!", "error");
        }
      }
    });
  }

  ngOnChanges(changes: { [propKey: string]: SimpleChange }) {
    this.filtrarDataGridLocal(this.filtro);
  }

  editarEsp(id: string): void {
    this.editar.emit(id);
  }

  editarRegrasEsp(id: string, centrocusto: string): void {
    this.editarRegras.emit(id + ";" + centrocusto);
  }

  sortColun(posicao: any): any {
    this.ordenacao[posicao].estado =
      this.ordenacao[posicao].estado == 2
        ? 1
        : this.ordenacao[posicao].estado == 1
        ? 0
        : 1;
    this.filtro.ordenacao = this.ordenacao[posicao].campo;

    for (let x = 0; x < this.ordenacao.length; x++) {
      if (posicao != x) {
        this.ordenacao[x].estado = 2;
      }
    }

    this.filtro.sorting = this.ordenacao[posicao].estado == 0 ? "DESC" : "ASC";
    this.filtrarDataGridLocal(this.filtro);
  }

  filtrarDataGridLocal(filtros: any): any {
    this.carregado.emit(false);
    this.conteudoCarregado = false;

    filtros.idcliente = this.idcliente;

    this.regraService.getPerfis(filtros).subscribe((response) => {
      if (response.success) {
        this.arrDados = response.perfis;
        this.filtro.paginaFinal = response.paginaFinal;
        this.filtro.quantidade = response.quantidade;
      }

      this.carregado.emit(true);
      this.conteudoCarregado = true;
    });
  }

  paginacaoDireto(filtros): any {
    this.filtro.paginaAtual = filtros;
    this.filtrarDataGridLocal(this.filtro);
  }
}
