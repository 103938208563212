import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class ProdutoService {
  constructor(private http: HttpClient) {}

  getProdutos(filtros: any): Observable<any> {
    return this.http.get('produto', {
      params: filtros,
    });
  }

  getComboProdutos(filtros: any): Observable<any> {
    return this.http.get('produto/comboProdutos', {
      params: filtros,
    });
  }

  saveProdutos(dados: any): Observable<any> {
    return this.http.post('produto/salvar', dados);
  }
}
