import { Component, Input, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserService } from '../../services/user.service';

@Component({
  selector: 'usuarios',
  templateUrl: './usuarios.component.html'
})

export class UsuariosComponent implements OnInit {
  @Input() tipo: number;
  @Input() idrelacionado: number = 0;

  evento = 1;

  carrPes = false;

  id: string;

  telaPesquisa = true;
  telaCadastrar = false;
  telaAlterar = false;
  telaVisualizar = false;

  private sub: any;

  alterarDados = true;

  constructor(private route: ActivatedRoute,
    private userService: UserService) {
    if (this.route.snapshot.data['tipo'] || this.route.snapshot.data['tipo'] == 0) {
      this.tipo = this.route.snapshot.data['tipo'];
    }

    this.alterarDados = this.userService.alterarDados;
  }

  ngOnInit(): void {
    this.sub = this.route.params.subscribe(params => {
      if (params['idrelacionado']) {
        this.idrelacionado = params['idrelacionado'];
      }
    });
  }

  cancelarTela(event: any): void {
    this.telaPesquisa = true;
    this.telaCadastrar = false;
    this.telaAlterar = false;
    this.telaVisualizar = false;

    if (event > 0 && this.carrPes) {
      this.evento *= -1;
    }
  }

  pesquisar(): void {
    this.telaPesquisa = true;
    this.telaCadastrar = false;
    this.telaAlterar = false;
    this.telaVisualizar = false;

    if (this.carrPes) {
      this.evento *= -1;
    }
  }

  cadastrar(): void {
    this.telaPesquisa = false;
    this.telaCadastrar = true;
    this.telaAlterar = false;
    this.telaVisualizar = false;
  }

  carregadoPesquisa(event: any) {
    this.carrPes = event;
  }

  editar(event: any) {
    if (event != '' && event != undefined) {
      this.id = event;
    }

    this.telaPesquisa = false;
    this.telaCadastrar = false;
    this.telaAlterar = true;
    this.telaVisualizar = false;
  }

  visualizar(event: any) {
    if (event != '' && event != undefined) {
      this.id = event;
    }

    this.telaPesquisa = false;
    this.telaCadastrar = false;
    this.telaAlterar = false;
    this.telaVisualizar = true;
  }
}
