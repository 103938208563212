import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";
import { Observable } from "rxjs";
import { TIPO_RELATORIO_FECHAMENTO_VALE_PEDAGIO } from "../enums/tipo-relatorio-fechamento.enum";

interface IExportarCsvResposta {
  fechamento: any;
  fechamentoItens: any[];
  veiculosPagantesTag: any[];
  tipoRelatorio: TIPO_RELATORIO_FECHAMENTO_VALE_PEDAGIO;
}

const TIPO_RELATORIO_MANIPULADOR = {
  [TIPO_RELATORIO_FECHAMENTO_VALE_PEDAGIO.SINTETICO]: (
    fechamentoItens: any[]
  ) => {
    const transacoesCsv = fechamentoItens.map((item) => ({
      dcadas: item.dcadas,
      dpassagem: item.dpassagem,
      cnpj: item.cnpjOperator,
      pedagio: item.pedagio,
      tag: `\u200C${item.tag}`,
      placa: item.placa,
      valor: item.valor.toString().replace(".", ","),
    }));

    return [
      {
        dcadas: "DATA",
        dpassagem: "PASSAGEM",
        cnpj: "CNPJ",
        pedagio: "PEDAGIO",
        tag: "TAG",
        placa: "PLACA",
        valor: "VALOR",
      },
      ...transacoesCsv,
    ];
  },
  [TIPO_RELATORIO_FECHAMENTO_VALE_PEDAGIO.SINTETICO_CENTRO_CUSTO]: (
    fechamentoItens: any[]
  ) => {
    const transacoesCsv = fechamentoItens.map((item) => ({
      centrocusto: item.centrocusto,
      cnpj: item.cnpjOperator,
      total: item.valortot.toString().replace(".", ","),
      totalTaxas: item.clitvalor.toString().replace(".", ","),
      quantidadeTotal: item.nqtdTotal,
    }));

    return [
      {
        centrocusto: "CENTRO DE CUSTO",
        cnpj: "CNPJ",
        total: "TOTAL",
        totalTaxas: "TOTAL DE TAXAS",
        quantidadeTotal: "QUANTIDADE TOTAL ITENS",
      },
      ...transacoesCsv,
    ];
  },
  [TIPO_RELATORIO_FECHAMENTO_VALE_PEDAGIO.ANALITICO_CENTRO_CUSTO]: (
    fechamentoItens: any[]
  ) => {
    let retorno = [];

    fechamentoItens.forEach((fechamentoItem) => {
      const dadosCsv = fechamentoItem.itens.map((item) => ({
        dcadas: item.dcadas,
        dpassagem: item.dpassagem,
        cnpj: item.cnpjOperator,
        pedagio: item.pedagio,
        tag: `\u200C${item.tag}`,
        placa: item.placa,
        valor: item.valor.toString().replace(".", ","),
      }));

      const itensGroupDadosCsv = fechamentoItem.itensGroup.map((itemGroup) => ({
        nomeproduto: itemGroup.descricao,
        valorproduto: itemGroup.valortot.toString().replace(".", ","),
      }));

      retorno = [
        ...retorno,
        { centrocusto: "CENTRO DE CUSTO" },
        { centrocusto: fechamentoItem.centrocusto },
        {
          dcadas: "DATA",
          dpassagem: "PASSAGEM",
          cnpj: "CNPJ",
          pedagio: "PEDAGIO",
          tag: "TAG",
          placa: "PLACA",
          valor: "VALOR",
        },
        ...dadosCsv,
        {
          nomeproduto: "Nome do Produto",
          valorproduto: "Valor",
        },
        ...itensGroupDadosCsv,
        { nomeproduto: "", valorproduto: "" },
        { nomeproduto: "", valorproduto: "" },
      ];
    });

    return retorno;
  },
  [TIPO_RELATORIO_FECHAMENTO_VALE_PEDAGIO.ANALITICO_VEICULO]: (
    fechamentoItens: any[]
  ) => {
    let retorno = [];

    fechamentoItens.forEach((veiculo) => {
      const transacoesCsv = veiculo.itens.map((transacao) => ({
        dcadas: transacao.dcadas,
        dpassagem: transacao.dpassagem,
        cnpj: transacao.cnpjOperator,
        pedagio: transacao.pedagio,
        tag: `\u200C${transacao.tag}`,
        placa: transacao.placa,
        valor: transacao.valor.toString().replace(".", ","),
      }));

      const grupoDadosCsv = veiculo.itensGroup.map((grupo) => ({
        nomeproduto: grupo.descricao,
        valorproduto: grupo.valortot.toString().replace(".", ","),
      }));

      retorno = [
        ...retorno,
        { centrocusto: "VEÍCULO" },
        { centrocusto: veiculo.centrocusto },
        {
          dcadas: "DATA",
          dpassagem: "PASSAGEM",
          cnpj: "CNPJ",
          pedagio: "PEDAGIO",
          tag: "TAG",
          placa: "PLACA",
          valor: "VALOR",
        },
        ...transacoesCsv,
        {
          nomeproduto: "Nome do Produto",
          valorproduto: "Valor",
        },
        ...grupoDadosCsv,
        { nomeproduto: "", valorproduto: "" },
        { nomeproduto: "", valorproduto: "" },
      ];
    });

    return retorno;
  },
};

@Injectable()
export class FechamentoService {
  constructor(private http: HttpClient) {}

  getFechamento(filtros: any): Observable<any> {
    return this.http.get("fechamento", {
      params: filtros,
    });
  }

  getFechamentoGNF(filtros: any): Observable<any> {
    return this.http.get("fechamento/gestaonf", {
      params: filtros,
    });
  }

  getFechamentoVP(filtros: any): Observable<any> {
    return this.http.get("fechamento/valepedagio", {
      params: filtros,
    });
  }

  getFechamentoCursos(filtros: any): Observable<any> {
    return this.http.get("fechamento/curso", {
      params: filtros,
    });
  }

  getFechamentoDesconto(filtros: any): Observable<any> {
    return this.http.get("fechamento/descontos", {
      params: filtros,
    });
  }

  addDesconto(dados: any): Observable<any> {
    return this.http.post("fechamento/descontos", dados);
  }

  exportarCSV({
    fechamento,
    fechamentoItens,
    tipoRelatorio,
    veiculosPagantesTag,
  }: IExportarCsvResposta): { [key: string]: string }[] {
    let arquivoCsv: { [key: string]: string }[] = [
      {
        tipoTaxa: "Tipo de taxas",
        totalTaxa: "Total",
      },
      {
        tipoTaxa: "Taxa de aquisição",
        totalTaxa: fechamento.arrTaxas.fechtaxas.adesao
          .toString()
          .replace(".", ","),
      },
      {
        tipoTaxa: "Taxa Por TAG",
        totalTaxa: (fechamento.arrTaxas.fechtaxas.taxaMensalidade =
          fechamento.arrTaxas.fechtaxas.taxaMensalidade
            .toString()
            .replace(".", ",")),
      },
      {
        tipoTaxa: "Qtd de TAG",
        totalTaxa: fechamento.arrTaxas.fechtaxas.qtdTag
          .toString()
          .replace(".", ","),
      },
      {
        tipoTaxa: "Mensalidade",
        totalTaxa: fechamento.arrTaxas.fechtaxas.mensalidade
          .toString()
          .replace(".", ","),
      },
    ];

    arquivoCsv = [
      ...arquivoCsv,
      ...this.getDetalhamentoMensalidade(veiculosPagantesTag, tipoRelatorio),
      ...TIPO_RELATORIO_MANIPULADOR[tipoRelatorio](fechamentoItens),
    ];

    return arquivoCsv;
  }

  exportarCSVCursos(fechamentoItens) {
    const transacoesCsv = fechamentoItens.map((item) => ({
      dataCompra: item.dataCompra,
      nomeCurso: item.nomeCurso,
      cnome: item.cnome,
      valor: item.valor.toString().replace(".", ","),
    }));

    return [
      {
        dataCompra: "DATA",
        curso: "CURSO",
        cnome: "MOTORISTA",
        valor: "VALOR",
      },
      ...transacoesCsv,
    ];
  }

  recalculaCentroCusto(
    modulo: number,
    idfech: number,
    inicio: string,
    fim: string
  ) {
    return this.http.post("fechamento/reprocessar-separacao", {
      modulo,
      idfech,
      inicio,
      fim,
    });
  }

  atualizarBoletoNota(modulo: number, idfech: number) {
    return this.http.post("fechamento/atualizar-boleto-nota", {
      modulo,
      idfech,
    });
  }

  atualizarBoletoNotaEsp(modulo: number, idfech: number, idregional: number) {
    return this.http.post("fechamento/atualizar-boleto-nota-esp", {
      modulo,
      idfech,
      idregional,
    });
  }

  setTaxa(data: {
    idfech: string;
    taxa: number;
    valor: number;
    motivo: string;
    modulo: number;
    total: number;
  }) {
    return this.http.post(`fechamento/taxa`, data);
  }

  removeTaxa(data: {
    idfech: string;
    taxa: string;
    valor: number;
    motivo: string;
    modulo: number;
  }) {
    return this.http.post(`fechamento/taxa-remocao`, data);
  }

  getHistoricoTaxas(idfech: string, modulo: number) {
    return this.http.get(`fechamento/taxa?id=${idfech}&modulo=${modulo}`);
  }

  getHistoricoTaxasRemocao(idfech: string, modulo: number) {
    return this.http.get(
      `fechamento/taxa-remocao?id=${idfech}&modulo=${modulo}`
    );
  }

  getDetalhamentoMensalidade(
    veiculosPagantesTag: any[],
    tipoRelatorio: TIPO_RELATORIO_FECHAMENTO_VALE_PEDAGIO
  ) {
    if (veiculosPagantesTag.length > 0) {
      let retorno = [
        {},
        { centrocusto: "DETALHAMENTO DA MENSALIDADE" },
        tipoRelatorio ==
        TIPO_RELATORIO_FECHAMENTO_VALE_PEDAGIO.ANALITICO_CENTRO_CUSTO
          ? {
              tag: "CENTRO DE CUSTO",
              valor: "VALOR",
            }
          : {
              tag: "TAG",
              placa: "PLACA",
              valor: "VALOR",
            },
      ];

      veiculosPagantesTag.forEach((tagMensalidade) => {
        retorno = [
          ...retorno,
          tipoRelatorio ==
          TIPO_RELATORIO_FECHAMENTO_VALE_PEDAGIO.ANALITICO_CENTRO_CUSTO
            ? {
                tag: tagMensalidade.centroCusto,
                valor: tagMensalidade.taxa.toString().replace(".", ","),
              }
            : {
                tag: `\u200C${tagMensalidade.tnumero}`,
                placa: tagMensalidade.placa,
                valor: tagMensalidade.taxa.toString().replace(".", ","),
              },
        ];
      });

      retorno = [...retorno, {}, { centrocusto: "DETALHAMENTO DE CONSUMO" }];

      return retorno;
    }

    return [];
  }
}
