// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.form-select {
  padding: 0.375rem 0.75rem;
  --bs-form-select-bg-img: none !important;
}`, "",{"version":3,"sources":["webpack://./src/app/modulos/shared/components/search-select/search-select.component.scss"],"names":[],"mappings":"AAAA;EACE,yBAAA;EACA,wCAAA;AACF","sourcesContent":[".form-select {\n  padding: 0.375rem 0.75rem;\n  --bs-form-select-bg-img: none !important;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
