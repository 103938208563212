import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class TransacaoService {
  constructor(private http: HttpClient) {}

  getTransacao(filtros: any): Observable<any> {
    return this.http.get('transacao', {
      params: filtros,
    });
  }

  liberarRestricao(transacaoData: any): Observable<any> {
    return this.http.post('transacao/liberar', {
      dados: transacaoData,
    });
  }

  descancelar(transacaoId: string): Observable<any> {
    return this.http.post('transacao/descancelar', { transacaoId });
  }

  transacaoWeb(transacao: string): Observable<any> {
    return this.http.post('cartao/transacaoweb', { transacao });
  }

  ajustarTransacao(transacao: any): Observable<any> {
    return this.http.post('transacao/ajustar', { transacao });
  }

  cancelarTransacao(transacao: any): Observable<any> {
    return this.http.post('transacao/cancelar', { transacao });
  }

  ajustarOdometro(transacao: any): Observable<any> {
    return this.http.post('transacao/ajustarodometro', { transacao });
  }

  getRejeitadas(filtros: any): Observable<any> {
    return this.http.get('rejeitadas', {
      params: filtros,
    });
  }

  cancelartransacao_xhptwzesa(transacao: any): Observable<any> {
    return this.http.post('transacao/cancelartransacao-xhptwzesa', transacao);
  }

  qrcode(dados: any): Observable<any> {
    return this.http.post('cartao/qrcode', dados);
  }
}
