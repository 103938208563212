import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { ClienteService } from "./modulos/clientes/cliente.service";

@Component({
  selector: "logout",
  template: "",
})
export class LogoutComponent {
  constructor(private router: Router, private clienteService: ClienteService) {
    let login = localStorage.getItem("login");
    let version = localStorage.getItem("version");
    localStorage.clear();
    localStorage.setItem("version", version);

    if (login) localStorage.setItem("login", login);

    this.clienteService.logout();

    try {
      window.stop();
    } catch (exception) {
      document.execCommand("Stop");
    }

    this.router.navigate(["login"]);
  }
}
