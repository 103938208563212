import { Component, Input, OnInit } from '@angular/core';
import { InfoVeiculoDetalhe } from '../../models/veiculo-detalhe.model';

@Component({
  selector: 'app-cadastro',
  templateUrl: './cadastro.component.html',
  styleUrls: ['./../../detalhes-veiculo.component.css'],
})
export class CadastroComponent implements OnInit {
  @Input() vehicleData: InfoVeiculoDetalhe;

  constructor() {}

  ngOnInit() {}
}
