import { AfterViewInit, Directive, ElementRef, Renderer2 } from "@angular/core";

@Directive({
  selector: "[requiredInput]",
})
export class RequiredDirective implements AfterViewInit {
  constructor(private _elemRef: ElementRef, private _renderer: Renderer2) {}

  ngAfterViewInit(): void {
    const el = this._elemRef.nativeElement as HTMLElement;
    this._renderer.setProperty(
      this._elemRef.nativeElement,
      "innerHTML",
      el.innerHTML + ' <span style="color: red;">*</span>'
    );
  }
}
