import { Component, OnInit, ViewChild } from "@angular/core";
import { ClienteService } from "../../../../clientes/cliente.service";
import { InfoManutencaoService } from "../../../../manutencao/info.manutencao.service";

@Component({
  selector: "informativo-cliente-manu",
  templateUrl: "./informativo-cliente-manu.component.html",
  styleUrls: ["./informativo-cliente-manu.component.css"],
})
export class InformativoClienteManuComponent implements OnInit {
  informativo = {
    OrcCancelado: "",
    OrcRespondido: "",
    OrcSolicitado: "",
    OrcAguardandoResposta: "",

    listaOSPrevistas: [],
    listaOSAbertas: "",
    listaOSFechadas: "",
    listaVeiculosEmManutencao: "",
    listaVeiculosEntregues: "",

    totalOSPrevistas: { nOSPrevistas: 0 },
    totalOSAbertas: { nOSAbertas: 0 },
    totalOSFechadas: { nOSFechadas: 0 },
    totalVeiculosEmManutencao: { totalVeiculos: 0 },
    totalVeiculosEntregues: { totalVeiculos: 0 },
  };

  cliente: any;
  carregado = false;

  itemSel: any;
  visOk = false;

  showModalAlertaManutencao = false;
  showModalAlertaManutencaoItem = false;

  constructor(
    private clienteService: ClienteService,
    private infoManutService: InfoManutencaoService
  ) {
    this.cliente = this.clienteService.getCliente();
  }

  ngOnInit() {
    let filtro = { idcliente: this.cliente.idcliente };

    this.infoManutService.getInfoManutencao(filtro).subscribe((response) => {
      if (response && response.success) {
        this.informativo = response;
      }

      this.carregado = true;
    });
  }

  abrirModalOSPrevistas(): void {
    this.showModalAlertaManutencao = true;
  }

  visItens(itemSel): void {
    this.itemSel = itemSel;
    this.visOk = true;

    this.showModalAlertaManutencaoItem = true;
  }
}
