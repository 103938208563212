import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "filterTaxaModulos",
})
export class FilterTaxaModulos implements PipeTransform {
  transform(
    taxas: any[],
    modulo: number,
    prePago: number,
    tipo: boolean = false
  ): any {
    if (!tipo) {
      return taxas.filter(
        (item) => item.taxamodulo == modulo && item.prepago == prePago
      );
    }

    return taxas.filter(
      (item) =>
        item.modulos.split(",").includes(modulo.toString()) &&
        item.prepago == prePago
    );
  }
}
