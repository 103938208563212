import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class PublicoService {
  constructor(private http: HttpClient) {}

  getData(): Observable<any> {
    return this.http.get('publico/getData');
  }
}
