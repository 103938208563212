// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.linhaCancelada {
  background-color: #ffebee;
  color: #000;
  opacity: 1;
}

.linhaPendente {
  background-color: #ffe594;
  color: #000;
  opacity: 1;
}`, "",{"version":3,"sources":["webpack://./src/app/modulos/shared/core/transacoes/pesquisar/pesquisar-transacoes.component.scss"],"names":[],"mappings":"AAAA;EACE,yBAAA;EACA,WAAA;EACA,UAAA;AACF;;AAEA;EACE,yBAAA;EACA,WAAA;EACA,UAAA;AACF","sourcesContent":[".linhaCancelada {\n  background-color: #ffebee;\n  color: #000;\n  opacity: 1;\n}\n\n.linhaPendente {\n  background-color: #ffe594;\n  color: #000;\n  opacity: 1;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
