import {
  AfterViewInit,
  Component,
  Inject,
  OnInit,
  Renderer2,
} from "@angular/core";
import { UserService } from "../../modulos/shared/services/user.service";
import { ClienteService } from "../../modulos/clientes/cliente.service";
import { NavigationEnd, Router } from "@angular/router";
import { SidebarNotifyService } from "../../modulos/shared/services/sidebar-notify.service";
import { DOCUMENT } from "@angular/common";

@Component({
  selector: "app-menu",
  templateUrl: "./menu.component.html",
  styleUrls: ["./menu.component.scss"],
})
export class AppMenuComponent implements OnInit, AfterViewInit {
  nomeUser: string;
  perfilUser: string;
  ClienteUser = "";
  clienteChecklist: any;

  idperfil = 0;

  menus = [];

  showMenu = false;

  constructor(
    private userService: UserService,
    private clienteService: ClienteService,
    public router: Router,
    private sidebarNotifyService: SidebarNotifyService,
    @Inject(DOCUMENT) private document: Document,
    private renderer: Renderer2
  ) {
    this.nomeUser = this.userService.usuario.nome;
    this.perfilUser = this.userService.usuario.perfil;
    this.idperfil = this.userService.usuario.idperfil;
    this.clienteChecklist = this.clienteService.getClienteChecklist();

    if (
      this.userService.usuario.idcliente != undefined &&
      this.userService.usuario.idcliente != 0 &&
      !this.clienteService.getCliente()
    ) {
      this.clienteService
        .getClienteHttp(this.userService.usuario.idcliente)
        .subscribe((response) => {
          if (response.success) {
            this.ClienteUser = response.cliente.cliente;
          }
        });
    } else if (
      this.userService.usuario.idcliente != undefined &&
      this.clienteService.getCliente() &&
      this.clienteService.getCliente().idcliente != ""
    ) {
      this.ClienteUser = this.clienteService.getCliente().cliente;
    }

    this.sidebarNotifyService.on().subscribe((data) => {
      if (data == "abrir") {
        this.renderer.addClass(this.document.body, "sidebar-toggled");
        this.showMenu = true;
      } else {
        this.renderer.removeClass(this.document.body, "sidebar-toggled");
        this.showMenu = false;
      }
    });
  }

  ngAfterViewInit(): void {
    this.router.events.subscribe((event) => {
      if (event instanceof NavigationEnd) {
        this.renderer.removeClass(this.document.body, "sidebar-toggled");
        this.showMenu = false;
      }
    });
  }

  ngOnInit() {
    switch (this.perfilUser) {
      case "Cliente":
      case "Gestor de Frota":
      case "Financeiro do Cliente":
      case "Consultas do Cliente":
        this.menus = [
          {
            nome: "Empresa",
            tela: "/empresa",
            icon: "fa-buildings",
            sub: [],
          },
          {
            nome: "Cartões",
            tela: "/cartoes",
            icon: "fa-credit-card",
            sub: [],
          },
          {
            nome: "Veiculos",
            icon: "fa-car",
            tela: "/veiculos",
            sub: [
              {
                nome: "Gerenciar",
                tela: "/veiculos/lista",
              },
              {
                nome: "Reservas",
                tela: "/veiculos/reservas",
              },
              {
                nome: "Contratos",
                tela: "/veiculos/contratos",
              },
            ],
          },
          {
            nome: "Credenciados",
            tela: "/credenciados",
            icon: "fa-shop",
            sub: [],
          },
          {
            nome: "Monitoramento",
            tela: "/monitoramento",
            icon: "fa-desktop",
            sub: [],
          },
          {
            nome: "Mapa de abastecimento",
            tela: "/mapa",
            icon: "fa-map",
            sub: [],
          },
        ];
        if (this.clienteChecklist != 0) {
          this.menus[2].sub.push({
            nome: "Checklist",
            tela: "/veiculos/checklist",
          });
        }

        break;
      case "Vendas do Credenciado":
        this.menus = [
          {
            nome: "Procedimento de Venda",
            tela: "/procedimentovenda",
            icon: "fa-buildings",
          },
          {
            //menu de venda
            nome: "Realizar venda",
            tela: "/realizarvenda",
            icon: "fa-money-bill-wave",
          },
        ];

        break;
      case "Credenciado":
      case "Financeiro do Credenciado":
        this.menus = [
          {
            nome: "Estabelecimento",
            tela: "/estabelecimento",
            icon: "fa-buildings",
          },
          {
            nome: "Procedimento de Venda",
            tela: "/procedimentovenda",
            icon: "fa-buildings",
          },
          {
            //menu de venda
            nome: "Realizar venda",
            tela: "/realizarvenda",
            icon: "fa-money-bill-wave",
          },
        ];

        break;
      case "Administrador de Sistema":
      case "Master":
      case "Administrador de Sub Rede":
      case "Representante":
        this.menus = [
          {
            nome: "Credenciados",
            tela: "/credenciados",
            icon: "fa-shop",
          },
          {
            nome: "Clientes",
            tela: "/clientes",
            icon: "fa-buildings",
          },
          {
            nome: "Implantações",
            tela: "/implantacoes",
            icon: "fa-briefcase-blank",
          },
          {
            nome: "Cartões",
            tela: "/cartoes",
            icon: "fa-credit-card",
          },
          {
            nome: "Veiculos",
            icon: "fa-car",
            tela: "/veiculos",
            sub: [
              {
                nome: "Gerenciar",
                tela: "/veiculos/lista",
              },
              {
                nome: "Reservas",
                tela: "/veiculos/reservas",
              },
              {
                nome: "Contratos",
                tela: "/veiculos/contratos",
              },
              {
                nome: "Checklist",
                tela: "/veiculos/checklist",
              },
            ],
          },
          {
            nome: "Monitoramento",
            tela: "/monitoramento",
            icon: "fa-desktop",
          },
          {
            nome: "Mapa de abastecimento",
            tela: "/mapa",
            icon: "fa-map",
          },
        ];
        if (this.perfilUser != "Representante") {
          this.menus.splice(2, 0, {
            nome: "Parceiros",
            tela: "/parceiros",
            icon: "fa-users",
          });
        }
        break;
      case "Parceiro":
        this.menus = [
          {
            nome: "Cargas",
            tela: "/parceiro",
            icon: "fa-buildings",
          },
        ];

        break;
    }
  }

  fecharMenu() {
    this.sidebarNotifyService.fire("fechar");
  }
}
