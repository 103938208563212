import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { BroadCastService } from '../../shared/services/broadcast.service';

@Injectable()
export class MessageEventFrota {
  constructor(private broadcaster: BroadCastService) {}

  fire(data: string): void {
    this.broadcaster.broadcast(MessageEventFrota, data);
  }

  on(): Observable<string> {
    return this.broadcaster.on<string>(MessageEventFrota);
  }
}
