import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class InfoManutencaoService {
  constructor(private http: HttpClient) {}

  getInfoManutencao(filtros: any): Observable<any> {
    return this.http.get('infomanutencao', {
      params: filtros,
    });
  }

  getInfoLineChart(filtros: any): Observable<any> {
    return this.http.get('infomanutencao/getInfoLineChart', {
      params: filtros,
    });
  }

  getInfoBarChart(filtros: any): Observable<any> {
    return this.http.get('infomanutencao/getInfoBarChart', {
      params: filtros,
    });
  }
}
