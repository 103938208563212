import { Component, OnInit } from "@angular/core";

import Swal from "sweetalert2";
import { CredenciadoService } from "src/app/modulos/credenciados/services/credenciado.service";
import { ActivatedRoute, Router } from "@angular/router";
import { CgcPipe } from "src/app/modulos/shared/pipes/cgc.pipe";
import { UserService } from "src/app/modulos/shared/services/user.service";
import { DatePipe } from "@angular/common";
import { FechamentoService } from "src/app/modulos/shared/services/fechamento.service";
import { ConsultaService } from "src/app/modulos/abastecimento/consultas-admin/componenteconsultas/consulta.service";

@Component({
  selector: "app-fechamentos",
  templateUrl: "./transacoes-credenciados.html",
})
export class TransacoesCredenciadosComponent implements OnInit {
  conteudoCarregado = true;

  filtro = {
    situacao: "",
    modulo: 1,
    dataIni: "",
    dataFim: "",
    codtnCred: "",
    tipoCentralizadora: 0,
    codigoCentralizadoEscolhido: "",
    cnpjCred: "",
    doc: 0,
  };

  carregaRelatorio = false;
  caminhoRelatorio = "assets/reports/consulta_transacoes_credenciados.mrt";
  dadosRelatorio: any = {};
  tipoUsuario = 0;
  usuario: any;

  constructor(
    private consultaService: ConsultaService,
    private credenciadoService: CredenciadoService,
    private userService: UserService,
    private router: Router,
    private route: ActivatedRoute,
    private cgcPipe: CgcPipe
  ) {
    this.usuario = this.userService.usuario;
  }

  ngOnInit(): any {
    setTimeout(() => {
      if (
        !this.credenciadoService.getCredenciadoRelatorios().credenciadoConsulta
      ) {
        this.voltar();
      } else {
        this.preparaConsulta();
      }
    }, 100);
  }

  preparaConsulta() {
    this.tipoUsuario = this.userService.tipoUsuario;

    this.filtro.codtnCred =
      this.credenciadoService.getCredenciadoRelatorios().credenciadoConsulta.codtn;
    this.filtro.modulo =
      this.credenciadoService.getCredenciadoRelatorios()
        .idModuloCredenciadoConsulta ?? 1;
  }

  voltar(): void {
    this.router.navigate([".."], { relativeTo: this.route });
  }

  consultarDados(): void {
    this.conteudoCarregado = false;
    this.carregaRelatorio = false;
    let cabec = {
      hoje: new Date(),
      credenciado:
        this.credenciadoService.getCredenciadoRelatorios().credenciadoConsulta
          .credenciado,
      cnpjCredenciado: this.cgcPipe.transform(
        this.credenciadoService.getCredenciadoRelatorios().credenciadoConsulta
          .cpfcnpj
      ),
    };
    this.consultaService.getTransacoesCredenciados(this.filtro).subscribe({
      next: (response) => {
        if (!response.success) {
          Swal.fire("Erro", response.message, "error").then(() => {
            this.conteudoCarregado = true;
          });
          return;
        }
        let lista = response.dados.map((transacao) => ({
          ...transacao,
          cpfcnpj: this.cgcPipe.transform(transacao.cpfcnpj),
        }));
        let dados = {
          cabec: cabec,
          lista: lista,
        };

        this.dadosRelatorio = {};
        this.dadosRelatorio = dados;
        this.conteudoCarregado = true;
        this.carregaRelatorio = true;
      },
      error: (err) => {
        Swal.fire("Erro", err.message, "error").then(() => {
          this.conteudoCarregado = true;
        });
      },
    });
    /* .getCartaCircularizacaoCred(this.filtro)
      .subscribe((response) => {
        if (response.success) {
          let lista = response.dados.map(
            dados => {
              dados.cpfcnpj = this.cgcPipe.transform(dados.cpfcnpj);
              dados.Data = this.datePipe.transform(dados.Data.substring(0, 9), 'dd/MM/YYYY');
              return dados;
            }
          );

          response.cabec.cnpjCredenciado = this.cgcPipe.transform(response.cabec.cnpjCredenciado);
          let cabec = response.cabec;
          let dados = {
            cabec: cabec,
            lista: lista,
          };
          this.dadosRelatorio = {};
          this.dadosRelatorio = dados;
          this.carregaRelatorio = true;
        }

        this.conteudoCarregado = true;
      }); */
  }
}
