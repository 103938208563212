import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

@Injectable()
export class RegraService {
  constructor(private http: HttpClient) {}

  getRegrasNivel(filtros: any): Observable<any> {
    return this.http.get("regras", {
      params: filtros,
    });
  }

  carregarLocalizacao(filtros: any): Observable<any> {
    return this.http.get("regras/localizacao", {
      params: filtros,
    });
  }

  chamarCredenciadosCertos(filtros: any): Observable<any> {
    return this.http.get("regras/chamarcredenciadoscertos", {
      params: filtros,
    });
  }

  getTodosProdutosRegras(filtros: any): Observable<any> {
    return this.http.get("regras/getprodutospadrao", {
      params: filtros,
    });
  }

  getLogsRegras(filtros: any): Observable<any> {
    return this.http.get("regras/metodos/getalteracoes", {
      params: filtros,
    });
  }

  salvarRegras(dados: any): Observable<any> {
    return this.http.post("regras", dados);
  }

  getPerfis(filtros: any): Observable<any> {
    return this.http.get("regras/perfil", {
      params: filtros,
    });
  }

  salvarRegrasManutencao(dados: any): Observable<any> {
    return this.http.post("regras/addProdutoHabilitadoManutencao", dados);
  }

  getRegrasManutencao(filtros: any): Observable<any> {
    return this.http.get("regras/getProdutoHabilitadoManutencao", {
      params: filtros,
    });
  }

  deletarPerfilRegras(filtros: any): Observable<any> {
    return this.http.delete("regras/perfil", {
      params: filtros,
    });
  }

  attPerfilRegras(dados: any): Observable<any> {
    return this.http.put("regras/perfil", dados);
  }

  addPerfilRegras(dados: any): Observable<any> {
    return this.http.post("regras/perfil", dados);
  }

  setInversao(dados: any): Observable<any> {
    return this.http.put("regras/trocainversao", dados);
  }
}
