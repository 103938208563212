import { Component, Output, EventEmitter, OnInit } from "@angular/core";
import { ClienteService } from "../../../clientes/cliente.service";
import { EmpresaService } from "../../services/empresa.service";
import { FormControl } from "@angular/forms";
import { debounceTime } from "rxjs";

@Component({
  selector: "busca-clientes",
  templateUrl: "./busca-cliente.component.html",
})
export class BuscaClientesComponent implements OnInit {
  filtros: any;
  inputdata: any;
  arrClientes: any;
  carregarConteudo = false;
  @Output() closeBusca = new EventEmitter<any>();

  @Output() codtn = new EventEmitter<string>();
  inputControl = new FormControl("");

  constructor(
    private clienteService: ClienteService,
    private empresaService: EmpresaService
  ) {
    this.filtros = {
      subredes: [],

      codtn: "",
      cliente: "",
    };

    this.empresaService.get().subscribe((response) => {
      if (response.success) {
        for (let i = 0; i < response.empresas.length; i++) {
          response.empresas[i].selecionado = true;
        }

        this.filtros.subredes = response.empresas;
        this.verificador("");
      }
    });

    this.inputdata = "";
  }

  ngOnInit(): void {
      this.inputControlValueChanges();
  }

  closeModal(): void {
    this.closeBusca.emit();
  }

  selecionarCliente(codtn: any): void {
    this.codtn.emit(codtn);
  }

  inputControlValueChanges() {
    this.inputControl.valueChanges
      .pipe(debounceTime(1000))
      .subscribe((value: string) => {
        this.verificador(value)
      });
  }

  verificador(inputdata: any){
    this.filtros.codtn = "";
      this.filtros.cliente = "";

      let pesquisa = false;

      if (this.isNumber(inputdata)) {
        if (inputdata.length == 5) {
          this.filtros.codtn = inputdata;
          pesquisa = true;
        } else {
          this.filtros.codtn = "";
        }
      } else {
        this.filtros.cliente = inputdata;
        pesquisa = true;
      }

      if (pesquisa) {
        this.carregarConteudo = true;
        this.clienteService
          .getComboClientesParam({...this.filtros, subredes: JSON.stringify(this.filtros.subredes)})
          .subscribe((response) => {
            this.arrClientes = response.cliente;
            this.carregarConteudo = false;
          });
      }
  }

  isNumber(n): boolean {
    return /^-?[\d.]+(?:e-?\d+)?$/.test(n);
  }
}
