// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.offcanvas-start {
  opacity: 0;
  pointer-events: none;
  transform: translateX(-100%);
  transition: all 0.4s ease;
}

.offcanvas-start.show {
  opacity: 1;
  pointer-events: all;
  transform: translateX(0);
}

.offcanvas {
  top: 67px;
  z-index: 1050;
  background: #2b343a;
  width: 270px;
  background-image: url('logoplusfrotamarca.9cfeefccdc0b0d5b.png');
  background-position: center 95%;
  background-size: 80%;
  background-repeat: no-repeat;
}

.mm-profile {
  background-color: #131f26;
  color: rgba(255, 255, 255, 0.6509803922);
}

.mm-profile.toggled > a:before {
  content: "\\f278";
  transform: scale(0);
}

.mm-profile > a:before {
  content: "\\f278";
  transform: scale(1);
}

.mm-profile > div:hover:first-child {
  background-color: rgba(255, 255, 255, 0.0392156863);
}

.mm-profile small {
  color: rgba(255, 255, 255, 0.3490196078);
}

.hover-white:hover,
.hover-white.active {
  color: white;
  font-weight: 500;
}

.hover-back:hover,
.hover-back.active {
  color: white;
  background-color: rgba(255, 255, 255, 0.0392156863);
}

.menu-item-color {
  color: rgba(255, 255, 255, 0.6509803922);
}

.sub-menu-item {
  color: #989898;
}`, "",{"version":3,"sources":["webpack://./src/app/template/menu/menu.component.scss"],"names":[],"mappings":"AAAA;EACE,UAAA;EACA,oBAAA;EACA,4BAAA;EACA,yBAAA;AACF;;AACA;EACE,UAAA;EACA,mBAAA;EACA,wBAAA;AAEF;;AACA;EACE,SAAA;EACA,aAAA;EACA,mBAAA;EACA,YAAA;EAEA,gEAAA;EACA,+BAAA;EACA,oBAAA;EACA,4BAAA;AACF;;AAEA;EACE,yBAAA;EACA,wCAAA;AACF;;AAEA;EACE,gBAAA;EACA,mBAAA;AACF;;AACA;EACE,gBAAA;EACA,mBAAA;AAEF;;AACA;EACE,mDAAA;AAEF;;AACA;EACE,wCAAA;AAEF;;AACA;;EAEE,YAAA;EACA,gBAAA;AAEF;;AACA;;EAEE,YAAA;EACA,mDAAA;AAEF;;AACA;EACE,wCAAA;AAEF;;AACA;EACE,cAAA;AAEF","sourcesContent":[".offcanvas-start {\n  opacity: 0;\n  pointer-events: none;\n  transform: translateX(-100%);\n  transition: all 0.4s ease;\n}\n.offcanvas-start.show {\n  opacity: 1;\n  pointer-events: all;\n  transform: translateX(0);\n}\n\n.offcanvas {\n  top: 67px;\n  z-index: 1050;\n  background: #2b343a;\n  width: 270px;\n\n  background-image: url(\"../../../assets/img/logoplusfrotamarca.png\");\n  background-position: center 95%;\n  background-size: 80%;\n  background-repeat: no-repeat;\n}\n\n.mm-profile {\n  background-color: #131f26;\n  color: #ffffffa6;\n}\n\n.mm-profile.toggled > a:before {\n  content: \"\\f278\";\n  transform: scale(0);\n}\n.mm-profile > a:before {\n  content: \"\\f278\";\n  transform: scale(1);\n}\n\n.mm-profile > div:hover:first-child {\n  background-color: #ffffff0a;\n}\n\n.mm-profile small {\n  color: #ffffff59;\n}\n\n.hover-white:hover,\n.hover-white.active {\n  color: white;\n  font-weight: 500;\n}\n\n.hover-back:hover,\n.hover-back.active {\n  color: white;\n  background-color: #ffffff0a;\n}\n\n.menu-item-color {\n  color: #ffffffa6;\n}\n\n.sub-menu-item {\n  color: #989898;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
