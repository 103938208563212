import { Component, Input } from "@angular/core";
import { UserService } from "../../services/user.service";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "informativo",
  templateUrl: "./informativo.component.html",
})
export class InformativoComponent {
  tipoUsuario = 1;
  @Input("modulo") modulo = 1;

  constructor(private userService: UserService, private route: ActivatedRoute) {
    this.tipoUsuario = this.userService.tipoUsuario;

    if (this.route.snapshot.data["modulo"]) {
      this.modulo = this.route.snapshot.data["modulo"];
    }
  }
}
