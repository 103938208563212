import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'somenteOleos'
})
export class OleosPipe implements PipeTransform {
  transform(items: any[]): any {
    return items.filter(item => (item.tipoproduto == 1));
  }
}
