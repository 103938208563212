import { Subscription } from "rxjs";
import { Component, OnInit, OnDestroy, Input } from "@angular/core";
import { VeiculoService } from "src/app/modulos/veiculos/veiculo.service";
import { ActivatedRoute, Router } from "@angular/router";
import {
  VeiculoDetalhe,
  InfoVeiculoDetalhe,
  FipeDataVeiculoDetalhe,
  IpvaVeiculoDetalhe,
  SpecsVeiculoDetalhe,
  RevisionPlanVeiculoDetalhe,
} from "./models/veiculo-detalhe.model";
import Swal from "sweetalert2";

@Component({
  selector: "detalhes-veiculo",
  templateUrl: "./detalhes-veiculo.component.html",
  styleUrls: ["./detalhes-veiculo.component.css"],
})
export class DetalhesVeiculoComponent implements OnInit, OnDestroy {
  @Input() placaVeiculo: string;
  @Input() modal = false;

  subscription = new Subscription();
  conteudoCarregado = false;
  vehicleData: InfoVeiculoDetalhe;
  fipeData: FipeDataVeiculoDetalhe;
  ipvaData: IpvaVeiculoDetalhe;
  specsData: SpecsVeiculoDetalhe[];
  revisionData: RevisionPlanVeiculoDetalhe[];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    private veiculoService: VeiculoService
  ) {}

  ngOnInit() {
    this.subscription.add(
      this.route.params.subscribe((params) => {
        if (params["placa"]) {
          this.placaVeiculo = params["placa"];
        }
        this.carregarDetalhes();
      })
    );
  }

  carregarDetalhes() {
    this.conteudoCarregado = false;

    const params = {
      placa: this.placaVeiculo,
    };

    this.subscription.add(
      this.veiculoService.getConsultaSuiv(params).subscribe((response) => {
        if (response.success) {
          try {
            // const data: VeiculoDetalhe = response.veiculoManutencao;
            const data: VeiculoDetalhe = JSON.parse(response.veiculoManutencao);

            if (!data.vehicleInfo) {
              this.veiculoNaoEncontrado();
            }

            this.vehicleData = data.vehicleInfo;

            this.fipeData = data.vehicleInfo.fipeDataCollection[0];
            this.ipvaData = data.ipva;
            this.specsData = data.technicalSpecs;
            this.revisionData = data.revisionPlan;
            this.conteudoCarregado = true;
          } catch (error) {
            this.veiculoNaoEncontrado();
          }
        } else {
          this.veiculoNaoEncontrado();
        }
      })
    );
  }

  veiculoNaoEncontrado() {
    Swal.fire("Erro", "Veículo não encontrado", "error");
    this.router.navigate(["veiculos/lista"]);
  }

  handleBack() {
    this.router.navigate(["veiculos/lista"]);
  }

  ngOnDestroy(): void {
    this.subscription.unsubscribe();
  }
}
