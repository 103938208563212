import { Component, Input, OnInit } from "@angular/core";
import { RegraService } from "../../abastecimento/regras/regras.service";
import { LocalidadeService } from "../../shared/services/localidade.service";
import { CredenciadoService } from "../../credenciados/services/credenciado.service";
import { ActivatedRoute, Router } from "@angular/router";
import { UserService } from "../../shared/services/user.service";
import { ProdutoService } from "../../shared/services/produto.service";
import Swal from "sweetalert2";

@Component({
  selector: "regras-manutencao",
  templateUrl: "./regras.component.html",
  styleUrls: ["./regras.component.css"],
})
export class RegrasManutencaoComponent implements OnInit {
  @Input("idrelacionado") idrelacionado = "0";
  @Input("idcliente") idcliente = "0";

  @Input("regranivel") regranivel: number;

  conteudoCarregado = false;

  regras: any = {
    tipoproduto: [
      {
        status: false,
        desable: false,
        valor: 105,
        codTln: 701,
        tipoproduto: 5,
        name: "Manutenção Avançada",
      },
    ],
  };

  userdata: any;

  private sub: any;

  voltar = false;

  constructor(
    private regraService: RegraService,
    private produtoService: ProdutoService,
    private localidadeService: LocalidadeService,
    private credenciadoService: CredenciadoService,
    private router: Router,
    private userService: UserService,
    private route: ActivatedRoute
  ) {
    this.userdata = userService.usuario;

    if (this.userdata.idcliente != "") {
      this.idcliente = this.userdata.idcliente;
    }

    if (
      this.route.snapshot.data["regranivel"] ||
      this.route.snapshot.data["regranivel"] == 0
    ) {
      this.regranivel = this.route.snapshot.data["regranivel"];
    }

    if (this.route.snapshot.data["voltar"]) {
      this.voltar = true;
    }
  }

  ngOnInit(): any {
    this.sub = this.route.params.subscribe((params) => {
      if (params["idcliente"] && params["idrelacionado"]) {
        this.idcliente = params["idcliente"];
        this.idrelacionado = params["idrelacionado"];
      }

      this.regras.tipoproduto[0].nivel = this.regranivel;

      if (this.regranivel == 1) {
        this.regras.tipoproduto[0].desable = true;
      }

      let dadosRegrasManutencao = {
        idcliente: this.idcliente,
        idrelacionado: this.idcliente,
        produtosValidos: this.regras,
      };

      dadosRegrasManutencao.produtosValidos = JSON.stringify(
        dadosRegrasManutencao.produtosValidos
      );

      this.regraService
        .getRegrasManutencao(dadosRegrasManutencao)
        .subscribe((response) => {
          if (response.success) {
            for (let i = 0; i < this.regras.tipoproduto.length; i++) {
              if (
                response.tipoproduto.find(
                  (item: any) => item.codTln == this.regras.tipoproduto[0].codtn
                )
              ) {
                this.regras.tipoproduto.status = true;
              }
            }

            this.conteudoCarregado = true;
          }
        });
    });
  }

  salvarAsRegras() {
    let dadosRegrasManutencao = {
      idcliente: this.idcliente,
      idrelacionado: this.idcliente,
      produtosValidos: this.regras,
    };

    this.regraService
      .salvarRegrasManutencao(dadosRegrasManutencao)
      .subscribe((response) => {
        if (response.success) {
          Swal.fire("", "Produto registrado com sucesso!", "success");
        } else {
          Swal.fire("Problema ao cadastrar!", response.message, "error");
        }
      });
  }
}
