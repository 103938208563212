import { Component, Input, OnInit, Output } from "@angular/core";
import { FormControl } from "@angular/forms";
import { Subject, debounceTime } from "rxjs";
import { ClienteService } from "src/app/modulos/clientes/cliente.service";
import { UserService } from "../../services/user.service";

@Component({
  selector: "app-select-codtn",
  templateUrl: "./select-codtn.component.html",
  styleUrls: ["./select-codtn.component.scss"],
})
export class SelectCodtnComponent implements OnInit {
  showModalBuscaCliente: boolean = false;
  tipoUsuario = 0;

  @Input() clientData: any = { codtn: "", idcliente: 0, ccliente: "" };
  @Output() clientDataChange = new Subject();
  @Input() codtn = "";
  codtnControl = new FormControl("");

  constructor(
    private clienteService: ClienteService,
    private userService: UserService
  ) {}

  ngOnInit(): void {
    this.tipoUsuario = this.userService.tipoUsuario;
    this.codtnControlValueChanges();

    setTimeout(() => {
      this.codtnControl.setValue(this.codtn);
    }, 1);
  }

  codtnControlValueChanges() {
    this.codtnControl.valueChanges
      .pipe(debounceTime(1000))
      .subscribe((value: string) => {
        if (value.length == 5) {
          this.setClienteCodtn(value);
        }
      });
  }

  setClienteCodtn(codtn: string): void {
    if (codtn.length >= 5) {
      this.clientData.codtn = codtn;
      this.clienteService.getClienteCodtn({ codtn }).subscribe((response) => {
        if (response.success) {
          delete response.success;
          this.clientData = response;
          this.clientDataChange.next(response);
          this.clienteService.idcliente = response.idcliente;
        }
      });
    } else if (codtn.length == 0) {
      this.clientDataChange.next({ codtn: "", idcliente: 0, ccliente: "" });
      this.clientData = { codtn: "", idcliente: 0, ccliente: "" };
    }
  }
}
