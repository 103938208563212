import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'cortarLabel'
})
export class CortarLabelPipe implements PipeTransform {
  transform(value: string, tam: number) {
    if (value.length > tam) {
      return value.substring(0, tam - 3) + '...';
    }
    return value;
  }
}
