import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class DesempenhoService {
  constructor(private http: HttpClient) {}

  // Dados para Grafico Informativo
  getInformativoRedeCliente(filtros): Observable<any> {
    return this.http.get('desempenho/informativoredecliente', {
      params: filtros,
    });
  }

  getInformativoRedeConsumo(filtros): Observable<any> {
    return this.http.get('desempenho/informativoredeconsumo', {
      params: filtros,
    });
  }

  getInformativoRedeTaxa(filtros): Observable<any> {
    return this.http.get('desempenho/informativoredetaxa', {
      params: filtros,
    });
  }

  getInformativoRedeGeral(filtros): Observable<any> {
    return this.http.get('desempenho/informativoredegeral', {
      params: filtros,
    });
  }

  getInformativoRedeConsVeiculo(filtros): Observable<any> {
    return this.http.get('desempenho/informativoredeconsveiculo', {
      params: filtros,
    });
  }

  getInformativoRedeCredenciado(filtros): Observable<any> {
    return this.http.get('desempenho/informativoredecredenciado', {
      params: filtros,
    });
  }

  getInformativoRedeTransacao(filtros): Observable<any> {
    return this.http.get('desempenho/informativoredetransacao', {
      params: filtros,
    });
  }

  getInformativoRedeVeiculo(filtros): Observable<any> {
    return this.http.get('desempenho/informativoredeveiculo', {
      params: filtros,
    });
  }

  getInformativoClienteDesempenhoGeral(filtros: any): Observable<any> {
    return this.http.get('desempenho/clientedesempenhogeral', {
      params: filtros,
    });
  }

  getInformativoClientePrecoMedioCombustivel(filtros: any): Observable<any> {
    return this.http.get('desempenho/clienteprecomediocombustivel', {
      params: filtros,
    });
  }

  getInformativoClienteMediaKmLitroVeiculo(filtros: any): Observable<any> {
    return this.http.get('desempenho/clientemediakmlitroveiculo', {
      params: filtros,
    });
  }

  getInformativoClienteConsumoMedioVeiculo(filtros: any): Observable<any> {
    return this.http.get('desempenho/clienteconsumomedioveiculo', {
      params: filtros,
    });
  }

  getInformativoRedeQntdVeiculo(): Observable<any> {
    return this.http.get('desempenho/informativoredeqntdveiculo');
  }

  getInformativoRedeTicketMedio(): Observable<any> {
    return this.http.get('desempenho/informativoredeticketmedio');
  }
}
