import { Component, Input, OnChanges } from "@angular/core";

import { LocalidadeService } from "../../services/localidade.service";

@Component({
  selector: "localidade-combo",
  templateUrl: "./localidade.component.html",
})
export class LocalidadeComponent implements OnChanges {
  @Input() inputdados: any;
  @Input() obrigatorio = false;

  log: any = [];
  estados: any = [];
  bairros: any = [];
  ctipolog: any = [];
  localidades: any = [];

  conteudoCarregadoLog = false;
  conteudoCarregadoCep = true;
  conteudoCarregadoBairro = false;
  conteudoCarregadoTipoLog = false;
  conteudoCarregadoLocalidade = false;

  constructor(private localidadeService: LocalidadeService) {}

  ngOnChanges(): any {
    if (this.inputdados.ctipolog)
      this.inputdados.ctipolog = this.inputdados.ctipolog.trim();

    this.getEstado();
    if (this.inputdados.cuf && this.inputdados.cuf != "") {
      this.getLocalidade(this.inputdados.cuf);
      this.getBairro(this.inputdados.idmun);
      this.getTipoLog(this.inputdados.idbai);
      this.getLog(this.inputdados.idbai, this.inputdados.ctipolog);
    } else if (this.inputdados.idlogr && this.inputdados.idlogr != "") {
      this.getLoge(this.inputdados.idlogr);
    } else {
      this.conteudoCarregadoBairro = true;
      this.conteudoCarregadoTipoLog = true;
      this.conteudoCarregadoLog = true;
      this.conteudoCarregadoCep = true;
    }
  }

  carregarLocalidade(estado: any): void {
    this.inputdados.idmun = "0";

    this.inputdados.idbai = "0";
    this.inputdados.ctipolog = "0";
    this.inputdados.idlogr = "0";
    this.inputdados.cep = "";
    this.inputdados.uf = estado;

    this.bairros = [];
    this.ctipolog = [];
    this.log = [];

    if (estado != "0") {
      this.getLocalidade(estado);
    } else {
      this.localidades = [];
    }
  }

  carregarBairro(localidade: any): void {
    this.inputdados.idbai = "0";

    this.inputdados.ctipolog = "0";
    this.inputdados.idlogr = "0";
    this.inputdados.cep = "";

    this.ctipolog = [];
    this.log = [];

    if (localidade != "0") {
      this.getBairro(localidade);
    } else {
      this.bairros = [];
    }
  }

  carregarTipoLog(bairro: any): void {
    this.inputdados.ctipolog = "0";

    this.inputdados.idlogr = "0";
    this.inputdados.cep = "";

    this.log = [];

    if (bairro != "0") {
      this.getTipoLog(bairro);
    } else {
      this.ctipolog = [];
    }
  }

  carregarLog(bairro: any, tipolog: any): void {
    this.inputdados.idlogr = "0";
    this.inputdados.cep = "";

    if (bairro != "0" && tipolog != "0") {
      this.getLog(bairro, tipolog);
    } else {
      this.log = [];
    }
  }

  selecionarCep(log: any): void {
    this.inputdados.cep = "";

    if (log != "") {
      this.getCep(log);
    }
  }

  getEstado(): void {
    this.localidadeService.getEstados().subscribe((response) => {
      if (response.success) {
        this.estados = response.estados;
        this.estados.forEach((element) => {
          element.label = element.cuf;
        });
      }

      this.conteudoCarregadoLocalidade = true;
    });
  }

  getLocalidade(estado: any): void {
    this.conteudoCarregadoLocalidade = false;

    this.localidadeService.getLocalidades(estado).subscribe((response) => {
      if (response.success) {
        this.localidades = response.localidades;
        this.localidades.forEach((element) => {
          element.label = element.cnomemun;
        });
      }

      this.conteudoCarregadoLocalidade = true;
    });
  }

  getBairro(localidade: any): void {
    this.conteudoCarregadoBairro = false;

    this.localidadeService.getBairros(localidade).subscribe((response) => {
      if (response.success) {
        this.bairros = response.bairros;
        this.bairros.forEach((element) => {
          element.label = element.cnomebai;
        });
      }

      this.conteudoCarregadoBairro = true;
    });
  }

  getTipoLog(bairro: any): void {
    this.conteudoCarregadoTipoLog = false;

    this.localidadeService.getTiposLog(bairro).subscribe((response) => {
      if (response.success) {
        this.ctipolog = response.ctipolog;
        this.ctipolog.forEach((element) => {
          element.label = element.ctipolog;
        });
      }

      this.conteudoCarregadoTipoLog = true;
    });
  }

  getLog(bairro: any, tipolog: any): void {
    this.conteudoCarregadoLog = false;

    this.localidadeService.getLog(bairro, tipolog).subscribe((response) => {
      if (response.success) {
        this.log = response.log;
        this.log.forEach((element) => {
          element.label = element.cnomelog;
        });
      }

      this.conteudoCarregadoLog = true;
    });
  }

  getLoge(idlogr: any): void {
    this.conteudoCarregadoLog = false;

    this.localidadeService.getLoge(idlogr).subscribe((response) => {
      if (response.success) {
        this.inputdados.cuf = response.log.cuf;
        this.inputdados.idmun = response.log.idmun;
        this.inputdados.idbai = response.log.idbai;
        this.inputdados.ctipolog = response.log.ctipolog;
        this.inputdados.cep = response.log.cep;

        this.getLocalidade(this.inputdados.cuf);
        this.getBairro(this.inputdados.idmun);
        this.getTipoLog(this.inputdados.idbai);
        this.getLog(this.inputdados.idbai, this.inputdados.ctipolog);
      }
    });
  }

  getCep(log: any): void {
    this.conteudoCarregadoCep = false;

    this.localidadeService.getCep(log).subscribe((response) => {
      if (response.success) {
        this.inputdados.cep = response.cep[0].cep;
      }

      this.conteudoCarregadoCep = true;
    });
  }
}
