import { Component, Input, OnInit } from '@angular/core';

import { ClienteService } from 'src/app/modulos/clientes/cliente.service';
import { VeiculoService } from 'src/app/modulos/veiculos/veiculo.service';

@Component({
  selector: 'log-ajuste-odometro',
  templateUrl: './log-ajuste-odometro.component.html',
  styleUrls: ['./../../visualizar-veiculos.component.css'],
})
export class LogAjusteOdometroComponent implements OnInit {
  @Input('arrVeiculos') arrVeiculos: any;

  idcliente: string;
  conteudoCarregado = false;
  arrLogAjusteOdometro: any[] = [];

  constructor(
    private veiculoService: VeiculoService,
    private clienteService: ClienteService
  ) { }

  ngOnInit(): void {
    this.idcliente = this.clienteService.idcliente;
    this.pegarAjusteOdometroPorVeiculo();
  }

  pegarAjusteOdometroPorVeiculo(): void {
    this.veiculoService
      .pegarLogAjusteOdometroPorVeiculo({
        placa: this.arrVeiculos.placa,
        idcliente: this.idcliente,
      })
      .subscribe((response) => {
        if (response.success) {
          this.arrLogAjusteOdometro = response.veiculoLogAjusteOdomentro;
        }

        this.conteudoCarregado = true;
      });
  }
}
