import { Component, Input, ViewChild, OnInit } from "@angular/core";
import { VeiculoService } from "src/app/modulos/veiculos/veiculo.service";

@Component({
  selector: "historico-transacoes",
  templateUrl: "./historico-transacoes.component.html",
  styleUrls: ["./../../visualizar-veiculos.component.css"],
})
export class HistoricoTransacoesComponent implements OnInit {
  @Input("idveiculo") idveiculo: string;

  idEsp: number;
  tipoEsp: string;
  tipoHistorico = "0";
  conteudoCarregado = false;
  visualizarTransacao: boolean;
  arrTransacoesVeiculos: any[] = [];

  constructor(private veiculoService: VeiculoService) { }

  ngOnInit(): void {
    this.pegarTransacoesVeiculos();
  }

  pegarTransacoesVeiculos(): void {
    this.veiculoService
      .getTransacoesVeiculos({
        idveiculo: this.idveiculo,
        idtipoproduto: this.tipoHistorico,
      })
      .subscribe((response) => {
        if (response.success) {
          this.arrTransacoesVeiculos = response.transacoesVeiculo;
        }

        this.conteudoCarregado = true;
      });
  }

  trocarHistorico(tipo: string) {
    this.tipoHistorico = tipo;
    this.conteudoCarregado = false;
    this.arrTransacoesVeiculos = [];
    this.pegarTransacoesVeiculos();
  }

  abrirModalTransacao(id: number, tipo: string): void {
    this.visualizarTransacao = false;
    this.idEsp = id;

    const tipoTransacao = {
      "1": "A",
      "2": "I",
      "3": "L",
      "4": "R",
    };

    this.tipoEsp = tipoTransacao[tipo];
    this.visualizarTransacao = true;
  }
}
