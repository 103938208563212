import { Component, Input, OnInit } from '@angular/core';

import { VeiculoService } from '../../../veiculo.service';

@Component({
  selector: 'tanques-combustiveis',
  templateUrl: './tanques-combustiveis.component.html',
  styleUrls: ['./../../visualizar-veiculos.component.css'],
})
export class TanquesCombustiveisComponent implements OnInit {
  @Input('idveiculo') idveiculo: number;
  @Input('arrVeiculos') arrVeiculos: any;

  conteudoCarregado = false;
  arrVeiculoCombustivel: any[] = [];

  constructor(private veiculoService: VeiculoService) { }

  ngOnInit(): void {
    this.pegarTanquesCombustivelPorVeiculo();
  }

  pegarTanquesCombustivelPorVeiculo(): void {
    this.veiculoService
      .pegarTanquesCombustivelPorVeiculo({ idveiculo: this.idveiculo })
      .subscribe((response) => {
        if (response.success) {
          this.arrVeiculoCombustivel = response.veiculoCombustivel;
        }

        this.conteudoCarregado = true;
      });
  }
}
