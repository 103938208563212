import { Component, OnInit } from "@angular/core";
import { VeiculoService } from "../../../veiculo.service";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "log-alteracoes",
  templateUrl: "./log-alteracoes.component.html",
})
export class LogAlteracoesVeiculoComponent implements OnInit {
  arrDados: any = [];

  exclusiveFields = ["combustivel", "motorista", "grupo_motorista"];

  camposVeiculo = {
    cregional: {
      label: "Centro de Custo",
    },
    csetor: {
      label: "Departamento",
    },
    cveiculo: {
      label: "Veículo",
    },
    lbloqueado: {
      label: "Situação do Cadastro",
      options: {
        0: "Ativo",
        1: "Bloqueado",
      },
    },
    motivobloqueio: {
      label: "Motivo do Bloqueio",
      options: {
        1: "DEVOLUÇÃO",
        2: "SUBSTITUIÇÃO",
        3: "MANUTENÇÃO",
        4: "SEM MOTIVO",
        5: "PENDÊNCIA DOCUMENTAL",
        6: "PENDÊNCIA ENVIO DE KM",
      },
    },
    familia: {
      label: "Família",
    },
    marca: {
      label: "Marca",
    },
    modelo: {
      label: "Modelo",
    },
    cor: {
      label: "Cor",
    },
    ctipofrota: {
      label: "Tipo de Frota",
    },
    limprimircartao: {
      label: "Imprimir Cartão",
      options: {
        0: "Não",
        1: "Sim",
      },
    },
    contrato: {
      label: "Contrato",
    },
    datainiciocontrato: {
      label: "Data Início de Vínculo do Contrato",
    },
    datafimveiculocontrato: {
      label: "Data Fim de Vínculo do Contrato",
    },
    valorTarifa: {
      label: "Valor da Tarifa",
    },
    anofabr: {
      label: "Ano de Fabricação",
    },
    ano: {
      label: "Ano do Modelo",
    },
    codveiculo: {
      label: "Código do Veículo",
    },
    ncinlindradas: {
      label: "Cilindradas",
    },
    placa: {
      label: "Placa",
    },
    cuf: {
      label: "Estado",
    },
    municipio: {
      label: "Município",
    },
    chassi: {
      label: "Chassi Número",
    },
    renavam: {
      label: "Renavam",
    },
    mesvenctoipva: {
      label: "Mês de Vencimento do IPVA",
    },
    dataaquisicao: {
      label: "Data de Aquisição",
    },
    datagarantia: {
      label: "Data de Garantia",
    },
    idtipooleo: {
      label: "Tipo de Oleo",
    },
    ncapreservoleo: {
      label: "Capacidade do Reservatório",
    },
    ntoleranreservoleo: {
      label: "Tolerância do Reservatório(%)",
    },
    lrestrireservoleo: {
      label: "Restringir Capacidade do Reservatório",
      options: {
        0: "Não",
        1: "Sim",
      },
    },
    lodometro: {
      label: "Odômetro",
      options: {
        0: "Não",
        1: "Sim",
      },
    },
    lhorimetro: {
      label: "Horimetro",
      options: {
        0: "Não",
        1: "Sim",
      },
    },
    lcontador_naorestringe: {
      label: "Não Controlar (Odômetro ou Horimetro)",
      options: {
        0: "Não",
        1: "Sim",
      },
    },
    ncontador: {
      label: "Odomêtro atual (km)",
    },
    ncontmax: {
      label: "Contador máximo (km)",
    },
    ntanques: {
      label: "Nº de Tanques",
    },
    lbicombustivel: {
      label: "Bi-Combustível(flex)",
      options: {
        0: "Não",
        1: "Sim",
      },
    },
    idtipocombustivel1: {
      label: "Tipo de Combustivel",
    },
    ncapacidade: {
      label: "Capacidade Tanque Principal (L/M³)",
    },
    tolerancia: {
      label: "Tolerância para Tanque Principal (%)",
    },
    lrest_capacidade: {
      label: "Restringir Capacidade Tanque Principal",
      options: {
        0: "Não",
        1: "Sim",
      },
    },
    idpfprod28Tanque1: {
      label: "100 - Gasolina Comum",
      options: {
        0: "Não",
        1: "Sim",
      },
    },
    idpfprod9Tanque1: {
      label: "101 - Gasolina Aditivada",
      options: {
        0: "Não",
        1: "Sim",
      },
    },
    idpfprod10Tanque1: {
      label: "102 - Gasolina Especial",
      options: {
        0: "Não",
        1: "Sim",
      },
    },
    idpfprod11Tanque1: {
      label: "103 - Etanol",
      options: {
        0: "Não",
        1: "Sim",
      },
    },
    idpfprod13Tanque1: {
      label: "105 - Diesel",
      options: {
        0: "Não",
        1: "Sim",
      },
    },
    idpfprod14Tanque1: {
      label: "106 - Diesel S10",
      options: {
        0: "Não",
        1: "Sim",
      },
    },
    idpfprod15Tanque1: {
      label: "107 - GNV",
      options: {
        0: "Não",
        1: "Sim",
      },
    },
    idpfprod16Tanque1: {
      label: "108 - Biodiesel",
      options: {
        0: "Não",
        1: "Sim",
      },
    },
    idpfprod153Tanque1: {
      label: "111 - Diesel S10 Aditivado",
      options: {
        0: "Não",
        1: "Sim",
      },
    },
    idpfprod106Tanque1: {
      label: "112 - Querosene",
      options: {
        0: "Não",
        1: "Sim",
      },
    },
    idpfprod28Tanque2: {
      label: "100 - Gasolina Comum",
      options: {
        0: "Não",
        1: "Sim",
      },
    },
    idpfprod9Tanque2: {
      label: "101 - Gasolina Aditivada",
      options: {
        0: "Não",
        1: "Sim",
      },
    },
    idpfprod10Tanque2: {
      label: "102 - Gasolina Especial",
      options: {
        0: "Não",
        1: "Sim",
      },
    },
    idpfprod11Tanque2: {
      label: "103 - Etanol",
      options: {
        0: "Não",
        1: "Sim",
      },
    },
    idpfprod13Tanque2: {
      label: "105 - Diesel",
      options: {
        0: "Não",
        1: "Sim",
      },
    },
    idpfprod14Tanque2: {
      label: "106 - Diesel S10",
      options: {
        0: "Não",
        1: "Sim",
      },
    },
    idpfprod15Tanque2: {
      label: "107 - GNV",
      options: {
        0: "Não",
        1: "Sim",
      },
    },
    idpfprod16Tanque2: {
      label: "108 - Biodiesel",
      options: {
        0: "Não",
        1: "Sim",
      },
    },
    idpfprod153Tanque2: {
      label: "111 - Diesel S10 Aditivado",
      options: {
        0: "Não",
        1: "Sim",
      },
    },
    idpfprod106Tanque2: {
      label: "112 - Querosene",
      options: {
        0: "Não",
        1: "Sim",
      },
    },
    idtipocombustivelreserv: {
      label: "Tipo de Combustivel",
      options: {
        1: "GASOLINA",
        6: "ARLA 32",
      },
    },
    ncapreserv: {
      label: "Capacidade Reservatorio (L)",
    },
    ntoleranreserv: {
      label: "Tolerância para o Reservatorio (%)",
    },
    lrestrireserv: {
      label: "Restringir Capacidade do Reservatorio",
      options: {
        0: "Não",
        1: "Sim",
      },
    },
    idtipocombustivel2: {
      label: "Tipo de Combustivel",
    },
    nconsumomedio2: {
      label: "Consumo Medio Gasolina Km/L",
    },
    ncapacidade2: {
      label: "Capacidade Tanque 2 (L/M³)",
    },
    tolerancia2: {
      label: "Tolerância para o Tanque 2 (%)",
    },
    nlimitelitros2: {
      label: "Limite em Litros Tanque 2 (L)",
    },
    lrest_capacidade2: {
      label: "Restringir Capacidade do Tanque 2",
      options: {
        0: "Não",
        1: "Sim",
      },
    },
    motorista: {
      label: "Motoristas",
    },
    grupo_motorista: {
      label: "Grupo de Motoristas",
    },
    combustivel: {
      label: "Combustíveis",
    },
  };

  dadosCreacao = {
    nome: "",
    dataCadastro: "",
  };
  dadosDelecao = {
    nome: "",
    dataCancelamento: "",
  };

  constructor(
    private veiculoService: VeiculoService,
    private route: ActivatedRoute
  ) {}

  ngOnInit() {
    this.route.params.subscribe({
      next: (params) => {
        this.veiculoService
          .getLogAlteracoes(params.id)
          .subscribe((response) => {
            if (response.success) {
              this.arrDados = response.dados;
            }
          });
        this.getDadosCriacao(params.id);
        this.getDadosDelecao(params.id);
      },
    });
  }

  getDadosCriacao(idveiculo: number) {
    this.veiculoService.getLogCadastrado(idveiculo).subscribe({
      next: (response) => {
        if (response.success) {
          this.dadosCreacao = response.usuario[0];
        }
      },
    });
  }

  getDadosDelecao(idveiculo: number) {
    this.veiculoService.getLogDeletado(idveiculo).subscribe({
      next: (response) => {
        if (response.success) {
          if (response.usuario.length > 0) {
            this.dadosDelecao = response.usuario[0];
            return;
          }
          this.dadosDelecao = {
            nome: "",
            dataCancelamento: "",
          };
        }
      },
    });
  }

  doIsNaN(variable): boolean {
    return (
      isNaN(variable) || variable == "" || !variable?.toString().includes(".")
    );
  }
}
