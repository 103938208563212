import { Component, OnInit } from "@angular/core";

import Swal from "sweetalert2";
import { CredenciadoService } from "src/app/modulos/credenciados/services/credenciado.service";
import { ActivatedRoute, Router } from "@angular/router";
import { ConsultaService } from "src/app/modulos/abastecimento/consultas-admin/componenteconsultas/consulta.service";
import { DescontoService } from "src/app/modulos/clientes/visualizar/desconto/desconto.service";
import { RealPipe } from "src/app/modulos/shared/pipes/real.pipe";
import { DecimalPipe } from "@angular/common";

@Component({
  selector: "consulta-desconto-cliente-credenciado",
  templateUrl: "./consulta-desconto-cliente-credenciado.component.html",
})
export class ConsultasDescontoClienteCredenciadosComponent implements OnInit {
  conteudoCarregado = false;

  preloaderClientesDesconto = false;

  filtro = {
    cliente: "",
    credenciado: "",
    dataDe: "",
    dataAte: "",
  };

  arrCliente: any = [];

  carregaRelatorio = false;
  caminhoRelatorio = "assets/reports/relatorio_desconto_credenciado.mrt";
  dadosRelatorio: any = {};
  idCredenciado: number = null;

  constructor(
    private consultaService: ConsultaService,
    private credenciadoService: CredenciadoService,
    private descontoService: DescontoService,
    private router: Router,
    private route: ActivatedRoute,
    private realPipe: RealPipe,
    private decimalPipe: DecimalPipe
  ) {}

  ngOnInit(): any {
    setTimeout(() => {
      if (
        !this.credenciadoService.getCredenciadoRelatorios().credenciadoConsulta
      ) {
        this.voltar();
      }else{
        this.preparaConsulta();
      }
    }, 100)
  }

  voltar(): void {
    this.router.navigate([".."], { relativeTo: this.route });
  }

  preparaConsulta() {
    this.idCredenciado =
      this.credenciadoService.getCredenciadoRelatorios().credenciadoConsulta.idcred;

    this.descontoService
      .getDesconto({ idcred: this.idCredenciado })
      .subscribe((response) => {
        if (response) {
          this.arrCliente = [];
          let filtroClientes: any = response.desconto;
          let x,
            p = 0;
          let achou = false;
          for (x = 0; x < filtroClientes.length; x++) {
            achou = false;
            for (p = 0; p < this.arrCliente.length; p++) {
              if (filtroClientes[x].Cliente == this.arrCliente[p].Cliente) {
                achou = true;
                break;
              }
            }
            if (!achou) {
              this.arrCliente.push(filtroClientes[x]);
            }
          }
        }
        this.arrCliente.forEach((element) => {
          element.label = element.Cliente + " - " + element.cnpjCliente;
        });
        this.conteudoCarregado = true;
      });
  }

  consultarDados(filtro: any): void {
    filtro.credenciado = this.idCredenciado;

    if (filtro.dataDe == "" || filtro.dataDe == "") {
      Swal.fire(
        "Datas Vazias",
        "As duas datas precisam estar preenchidas",
        "error"
      );
      return;
    }

    if (filtro.cliente == "") {
      Swal.fire("Cliente Vazio", "Você precisa escolher um cliente", "error");
      return;
    }

    this.conteudoCarregado = false;
    this.carregaRelatorio = false;
    this.preloaderClientesDesconto = true;
    this.consultaService
      .getTransacoesCredenciadoDesconto(JSON.stringify(filtro))
      .subscribe((response) => {
        if (response.success) {
          let lista = response.descontoCredenciado.transacoes.map(
            (transacao) => {
              transacao.descontoAplicado =
                transacao.tipoDesconto == 2
                  ? this.realPipe.transform(transacao.descontoAplicado)
                  : transacao.tipoDesconto == 1
                  ? this.decimalPipe.transform(
                      transacao.tipoDesconto,
                      "1.2-2",
                      "pt-br"
                    ) + "%"
                  : this.decimalPipe.transform(
                      transacao.tipoDesconto,
                      "1.2-2",
                      "pt-br"
                    );

              return transacao;
            }
          );
          let dados = {
            cabec: response.cabec,
            lista: lista,
          };
          this.dadosRelatorio = [];
          this.dadosRelatorio = dados;
          this.carregaRelatorio = true;
        }

        this.conteudoCarregado = true;
        this.preloaderClientesDesconto = false;
      });
  }
}
