import {Component} from '@angular/core';

@Component({
  selector: 'transacoes',
  templateUrl: './transacoes.component.html'
})

export class TransacoesComponent {

}
