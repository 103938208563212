import { Component, OnInit, Input } from "@angular/core";
import { FechamentoService } from "../../services/fechamento.service";
import Swal from "sweetalert2";

@Component({
  selector: "desconto-fechamentos",
  templateUrl: "./desconto-fechamentos.html",
})
export class DescontoFechamentosComponent implements OnInit {
  dados: any = [];
  conteudoCarregado = false;

  @Input() id: string;
  @Input() alterar: boolean;
  @Input() modulo: any;

  valorDisconto = 0;
  motivoDisconto = "";

  constructor(private fechamentoService: FechamentoService) {}

  ngOnInit(): any {
    this.atualizarDados();
  }

  atualizarDados(): void {
    this.conteudoCarregado = false;

    this.fechamentoService
      .getFechamentoDesconto({ id: this.id, modulo: this.modulo })
      .subscribe((response) => {
        if (response.success) {
          this.dados = response.dados;
        } else {
          Swal.fire("", response.message, "error");
        }

        this.conteudoCarregado = true;
      });
  }

  addDesconto(): void {
    let dados = {
      valor: this.valorDisconto,
      motivo: this.motivoDisconto,
      id: this.id,
      modulo: this.modulo,
    };

    if (!dados.valor || dados.valor <= 0) {
      Swal.fire(
        "Desconto Avulso",
        "Informe um valor para dar de desconto",
        "error"
      );
    }

    if (dados.motivo == "") {
      Swal.fire("Desconto Avulso", "Informe o motivo desse desconto", "error");
    }

    Swal.fire({
      title: "Desconto Avulso",
      text: "Adicionar desconto avulso no fechamento?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sim, dar desconto!",
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return this.fechamentoService.addDesconto(dados);
      },
    }).then((result) => {
      if (result.isConfirmed) {
        if (result.value.success) {
          Swal.fire(
            "Desconto Avulso",
            "Desconto adicionado com sucesso!",
            "success"
          ).then(() => {
            this.atualizarDados();
            this.valorDisconto = 0;
            this.motivoDisconto = "";
          });
        } else {
          Swal.fire("Desconto Avulso", result.value.message, "error");
        }
      }
    });
  }
}
