import { Component, OnInit, Output, EventEmitter, Input } from "@angular/core";

@Component({
  selector: "app-base-modal",
  templateUrl: "./base-modal.component.html",
  styleUrls: ["./base-modal.component.scss"],
})
export class BaseModalComponent implements OnInit {
  @Output() closeModal = new EventEmitter<void>();
  @Input() modalTitle: string;
  @Input() modalSize: "xs" | "sm" | "lg" | "xl" = "lg";

  isleaving = false;
  constructor() {}

  ngOnInit(): void {}

  onCloseModal() {
    this.isleaving = true;
    setTimeout(() => this.closeModal.emit(), 300);
  }
}
