import { Component, OnInit, Input, ElementRef } from "@angular/core";
import { RevisionPlanVeiculoDetalhe } from "../../models/veiculo-detalhe.model";

@Component({
  selector: "app-revisoes",
  templateUrl: "./revisoes.component.html",
  styleUrls: ["./../../detalhes-veiculo.component.css"],
})
export class RevisoesComponent implements OnInit {
  @Input() revisionData: RevisionPlanVeiculoDetalhe[];
  visiblePart: boolean[] = [];
  visibleInspection: boolean[] = [];

  constructor() {}

  ngOnInit() {
    this.visiblePart = Array.from(
      { length: this.revisionData.length },
      () => false
    );
    this.visibleInspection = Array.from(
      { length: this.revisionData.length },
      () => false
    );
  }

  expandirRevisao(index: number) {
    this.visiblePart[index] = !this.visiblePart[index];
    this.visibleInspection[index] = !this.visibleInspection[index];
  }
}
