import {
  Component,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  OnInit,
  Input,
} from "@angular/core";
import { CredenciadoService } from "../../../credenciados/services/credenciado.service";
import { FechamentoService } from "../../services/fechamento.service";
import { UserService } from "../../services/user.service";

@Component({
  selector: "busca-credenciado",
  templateUrl: "./busca-credenciado.component.html",
})
export class BuscaCredenciadoComponent implements OnInit {
  filtros = {
    redeSulcard: true,
    redePersonalNet: false,
    redeTriocard: false,
    redeLiveCard: false,
    redeBNBClube: false,

    statusAtivo: true,
    statusBloqueado: false,
    statusCancelado: false,
    gnv: false,

    segmento: "",

    codtn: "",
    razao: "",
    cnpj: "",
    cep: "",
    cuf: "",
    localidade: "",
    bairro: "",

    idcentralizadora: 0,
  };

  inputdata: any;
  arrCredenciados: any = [];
  typingTimer: any;
  carregarConteudo = false;

  @Output() codtn = new EventEmitter<string>();
  @Output() closeBusca = new EventEmitter<any>();
  @Input("idsegmento") idsegmento: string = "";
  @ViewChild("dataCredenciado", { static: true }) dataCredenciado: ElementRef;

  visible = false;

  constructor(
    private credenciadoService: CredenciadoService,
    private userService: UserService
  ) {
    this.filtros.idcentralizadora = userService.usuario.idcredenciado;

    if (this.idsegmento == undefined) {
      this.idsegmento = "";
    }

    this.inputdata = "";
  }

  ngOnInit() {
    this.filtros.segmento = this.idsegmento;
  }

  selecionarCredenciado(codtn: any, index): void {
    this.codtn.emit(codtn);
    this.arrCredenciados[index].btn = "btn-primary";
  }

  buscaCredenciados(filtros): void {
    this.carregarConteudo = true;
    this.credenciadoService
      .getCredenciados(this.filtros)
      .subscribe((response) => {
        this.arrCredenciados = response.credenciado;
        for (let i = 0; i < this.arrCredenciados.length; i++) {
          this.arrCredenciados[i].btn = "btn-success";
        }
        this.carregarConteudo = false;
      });
  }

  closeModal(): void {
    this.closeBusca.emit();
  }

  buscarCredenciado(inputdata): void {
    let doneTypingInterval = 1000;

    clearTimeout(this.typingTimer);
    if (!this.carregarConteudo) {
      if (inputdata != "") {
        this.typingTimer = setTimeout(verificador, doneTypingInterval);
      }
    }

    function verificador() {
      this.filtros.codtn = "";
      this.filtros.razao = "";
      this.filtros.cnpj = "";
      this.filtros.cep = "";
      this.filtros.cuf = "";
      this.filtros.localidade = "";
      this.filtros.bairro = "";

      let pesquisa = false;

      if (this.isNumber(inputdata)) {
        if (inputdata.length == 5) {
          this.filtros.codtn = inputdata;
          pesquisa = true;
        } else {
          this.filtros.codtn = "";
        }
      } else {
        this.filtros.razao = inputdata;
        pesquisa = true;
      }

      if (pesquisa) {
        this.carregarConteudo = true;
        this.credenciadoService
          .getCredenciados(this.filtros)
          .subscribe((response) => {
            this.arrCredenciados = response.credenciado;
            this.carregarConteudo = false;
            clearTimeout(this.typingTimer);
          });
      }
    }
  }

  isNumber(n): boolean {
    return /^-?[\d.]+(?:e-?\d+)?$/.test(n);
  }
}
