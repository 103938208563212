import {Component, Input} from '@angular/core';

@Component({
  selector: 'loading-app',
  templateUrl: './loading-app.html'
})

export class LoadingComponent {

  @Input('frase') frase: string;

  constructor() { }

}
