import { Component, OnInit } from "@angular/core";

import Swal from "sweetalert2";
import { CredenciadoService } from "src/app/modulos/credenciados/services/credenciado.service";
import { ActivatedRoute, Router } from "@angular/router";
import { ConsultaService } from "src/app/modulos/abastecimento/consultas-admin/componenteconsultas/consulta.service";
import { CgcPipe } from "src/app/modulos/shared/pipes/cgc.pipe";
import { UserService } from "src/app/modulos/shared/services/user.service";
import { DatePipe } from "@angular/common";

@Component({
  selector: "app-carta-circularizacao",
  templateUrl: "./carta-circularizacao.component.html",
})
export class CartaCircularizacaoComponent implements OnInit {
  conteudoCarregado = true;

  filtro = {
    cliente: "",
    credenciado: 0,
    dataDe: "",
    dataAte: "",
    tipoData: 1,
    tipoCentralizadora: 1,
    codigoCentralizadoEscolhido: "",
    modulo: 0,
    codtn: "",
  };

  carregaRelatorio = false;
  caminhoRelatorio =
    "assets/reports/relatorio_carta_circularizacao_credenciado.mrt";
  dadosRelatorio: any = {};
  tipoUsuario = 0;
  usuario: any;
  constructor(
    private consultaService: ConsultaService,
    private credenciadoService: CredenciadoService,
    private userService: UserService,
    private router: Router,
    private route: ActivatedRoute,
    private cgcPipe: CgcPipe,
    private datePipe: DatePipe
  ) {
    this.usuario = this.userService.usuario;
  }

  ngOnInit(): any {
    setTimeout(() => {
      if (
        !this.credenciadoService.getCredenciadoRelatorios().credenciadoConsulta
      ) {
        this.voltar();
      } else {
        this.preparaConsulta();
      }
    }, 100);
  }

  preparaConsulta() {
    this.tipoUsuario = this.userService.tipoUsuario;

    this.filtro.credenciado =
      this.credenciadoService.getCredenciadoRelatorios().credenciadoConsulta.idcred;
    this.filtro.codtn =
      this.credenciadoService.getCredenciadoRelatorios().credenciadoConsulta.codtn;
    this.filtro.modulo =
      this.credenciadoService.getCredenciadoRelatorios()
        .idModuloCredenciadoConsulta ?? 1;
  }

  voltar(): void {
    this.router.navigate([".."], { relativeTo: this.route });
  }

  consultarDados(): void {
    if (this.filtro.dataDe == "" || this.filtro.dataDe == "") {
      Swal.fire(
        "Datas Vazias",
        "As duas datas precisam estar preenchidas",
        "error"
      );
      return;
    }

    this.conteudoCarregado = false;
    this.carregaRelatorio = false;
    let cabec = {
      hoje: new Date(),
      credenciado:
        this.credenciadoService.getCredenciadoRelatorios().credenciadoConsulta
          .credenciado,
      cnpjCredenciado: this.cgcPipe.transform(
        this.credenciadoService.getCredenciadoRelatorios().credenciadoConsulta
          .cpfcnpj
      ),
    };

    this.consultaService
      .getCartaCircularizacaoCred(this.filtro)
      .subscribe((response) => {
        if (response.success) {
          let lista = response.dados.map((dados) => {
            dados.cpfcnpj = this.cgcPipe.transform(dados.cpfcnpj);
            dados.Data = this.datePipe.transform(
              dados.Data.substring(0, 9),
              "dd/MM/YYYY"
            );
            return dados;
          });

          let dados = {
            cabec: cabec,
            lista: lista,
          };
          this.dadosRelatorio = {};
          this.dadosRelatorio = dados;
          this.carregaRelatorio = true;
        }

        this.conteudoCarregado = true;
      });
  }
}
