import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'placa'
})
export class PlacaPipe implements PipeTransform {
  transform(value: string, args: any[]) {
    return value.substring(0, 3) + '-' +
      value.substring(3);
  }
}
