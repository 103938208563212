import { Injectable } from "@angular/core";
import { Observable } from "rxjs";
import { BroadCastService } from "./broadcast.service";

@Injectable()
export class SidebarNotifyService {
  constructor(private broadcaster: BroadCastService) {}

  fire(data: string): void {
    this.broadcaster.broadcast(SidebarNotifyService, data);
  }

  on(): Observable<string> {
    return this.broadcaster.on<string>(SidebarNotifyService);
  }
}
