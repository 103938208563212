import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit,
  ViewChild,
  ElementRef,
} from "@angular/core";
import { UsuariosService } from "../../../services/usuarios.service";
import { UserService } from "../../../services/user.service";
import { CentroCustoService } from "../../../../clientes/visualizar/centro-custo/centro-custo.service";
import { ClienteService } from "../../../../clientes/cliente.service";
import { EmpresaService } from "../../../services/empresa.service";
import { TIPO_PERFIL_USUARIO } from "../../../enums/tipo-perfil-usuario.enum";
import Swal from "sweetalert2";
import { DualList } from "../../../components/dual-list/dual-list";
import { FormControl, Validators } from "@angular/forms";

@Component({
  selector: "cadastrar-usuarios",
  templateUrl: "./cadastrar-usuarios.component.html",
})
export class CadastrarUsuariosComponent implements OnInit {
  senhaVisivel = false;

  @Input() tipo: number;
  @Input() idrelacionado: number;

  format = DualList.format;

  arrPerfis: any = [];
  arrCentroCusto: any = [];
  arrModulos: any = [];

  arrEmpresas: any = [];

  conteudoCarregado = true;
  conteudoCarregadoPerfil = true;
  conteudoCarregadoCentroCusto = true;
  conteudoCarregadoModulos = true;

  cpfValido = false;
  cpfCnpjEdicao = true;

  usuario = {
    id: 0,
    cgc: "",
    nome: "",
    email: "",
    idperfil: 3,
    centrocusto: 0,
    status: 1,
    senha: "",
    idempresa: "0",
    alttransacao: false,
    deltransacao: false,
    modulos: [],
  };

  formControlEmail = new FormControl("", [
    Validators.email,
    Validators.required,
  ]);

  temCentroCusto = false;

  @Output() cancelar = new EventEmitter<number>();

  constructor(
    private usuariosService: UsuariosService,
    private userService: UserService,
    private centroCustoService: CentroCustoService,
    private clienteService: ClienteService,
    private empresaService: EmpresaService
  ) {}

  voltarPesquisa(): void {
    this.cancelar.emit(0);
  }

  ngOnInit() {
    this.usuariosService
      .getPerfis({ tipo: this.tipo, idrelacionado: this.idrelacionado })
      .subscribe((response) => {
        if (response.success) {
          this.arrPerfis = response.perfis;
          this.arrPerfis.forEach((element) => {
            element.label = element.nome;
          });
        }

        this.conteudoCarregadoPerfil = true;
        this.verificarCarregado();
      });

    this.usuariosService
      .getModulosUsuario({ tipo: this.tipo, idrelacionado: this.idrelacionado })
      .subscribe((response) => {
        if (response.success) {
          this.arrModulos = response.modulos;
        }

        this.conteudoCarregadoModulos = true;
        this.verificarCarregado();
      });

    if (this.tipo == 1) {
      this.centroCustoService
        .getCentroCusto({
          idcliente: this.clienteService.getCliente().idcliente,
        })
        .subscribe((response) => {
          if (response.success) {
            this.arrCentroCusto = response.centrocusto;
            if (!this.temCentroCusto)
              this.arrCentroCusto.push({
                idregional: "0",
                cc: "Selecione",
              });
            this.arrCentroCusto.forEach((element) => {
              element.label = element.cc;
            });

            if (this.userService.usuario.centrocusto != 0) {
              this.temCentroCusto = true;
              this.usuario.centrocusto = this.userService.usuario.centrocusto;
            }

            this.conteudoCarregadoCentroCusto = true;
            this.verificarCarregado();
          }
        });
    }

    this.empresaService.get().subscribe((response) => {
      if (response.success) {
        this.arrEmpresas = response.empresas;
      }
    });
  }

  public get tiposPerfilUsuario(): typeof TIPO_PERFIL_USUARIO {
    return TIPO_PERFIL_USUARIO;
  }

  verificarCarregado() {
    if (
      this.conteudoCarregadoPerfil &&
      this.conteudoCarregadoCentroCusto &&
      this.conteudoCarregadoModulos
    ) {
      this.conteudoCarregado = true;
    } else {
      this.conteudoCarregado = false;
    }
  }

  verificar(): void {
    if (this.usuario.cgc != "") {
      this.conteudoCarregado = false;

      this.usuariosService
        .verificarCPF({
          tipo: this.tipo,
          idrelacionado: this.idrelacionado,
          cgc: this.usuario.cgc,
        })
        .subscribe((response) => {
          if (response.success) {
            this.cpfValido = true;
            this.cpfCnpjEdicao =
              this.userService.usuario.idperfil ==
              this.tiposPerfilUsuario.GESTOR_FROTA
                ? true
                : false;
          } else {
            Swal.fire("", response.message, "error");
          }

          this.conteudoCarregado = true;
        });
    }
  }

  salvar(usuario: any): void {
    if ((usuario.id == 0 || usuario.senha) && !this.validarSenha()) {
      Swal.fire("Problema ao cadastrar", "Favor verificar a senha", "error");
      return;
    }

    if (!usuario.nome || usuario.nome == "") {
      Swal.fire(
        "Problema ao cadastrar",
        "Favor preencher o nome do usuario.",
        "error"
      );
      return;
    }

    if (this.formControlEmail.invalid) {
      Swal.fire(
        "Problema ao cadastrar",
        "Favor preencher o e-mail do usuario.",
        "error"
      );
      return;
    }

    if (!usuario.idperfil || usuario.idperfil == "") {
      Swal.fire(
        "Problema ao cadastrar",
        "Favor selecionar o Perfil do usuario.",
        "error"
      );
      return;
    }

    if (usuario.idperfil == "6" && usuario.idempresa == "0") {
      Swal.fire(
        "Problema ao cadastrar",
        "Favor selecionar uma subrede.",
        "error"
      );
      return;
    }

    Swal.fire({
      title: "Finalizar cadastro?",
      text: "Você está prestes a cadastrar um novo usuario",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sim, cadastre!",
      showLoaderOnConfirm: true,
      preConfirm: () => {
        let arrModIds = DualList.objectToId(usuario.modulos, "id");
        return this.usuariosService.addUsuario(
          { ...usuario, modulos: arrModIds },
          this.tipo,
          this.idrelacionado
        );
      },
    }).then((result) => {
      if (result.isConfirmed) {
        if (result.value.success) {
          Swal.fire("", "Usuario cadastrado com sucesso!", "success").then(
            () => {
              this.cancelar.emit(1);
            }
          );
        } else {
          Swal.fire("", result.value.message, "error");
        }
      }
    });
  }

  validarSenha() {
    if (
      this.usuario.senha == "" ||
      !this.usuario.senha.match("^(?=.*[A-Z])") ||
      !this.usuario.senha.match("(?=.*[a-z])") ||
      !this.usuario.senha.match("(.*[0-9].*)") ||
      !this.usuario.senha.match("(?=.*[!@#$%^&*])") ||
      !this.usuario.senha.match(".{8,}")
    )
      return false;

    return true;
  }
}
