import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

@Injectable()
export class LocalidadeService {
  constructor(private http: HttpClient) {}

  getEstados(): Observable<any> {
    return this.http.post("localidade/estados", {});
  }

  getLocalidades(estado: any): Observable<any> {
    return this.http.post("localidade", {
      estado,
    });
  }

  getBairros(localidade: any, municipio = ""): Observable<any> {
    return this.http.post("localidade/bairros", {
      localidade,
      municipio,
    });
  }

  getTiposLog(bairro: any): Observable<any> {
    return this.http.post("localidade/tipolog", {
      bairro,
    });
  }

  getLog(bairro: any, ctipolog: any): Observable<any> {
    return this.http.post("localidade/log", {
      bairro,
      ctipolog,
    });
  }

  getLoge(idlogr: any): Observable<any> {
    return this.http.post("localidade/log", {
      idlogr,
    });
  }

  getCep(log: any): Observable<any> {
    return this.http.post("localidade/cep", {
      log,
    });
  }

  getMunicipioNome(cnomemun: string, cuf: string): Observable<any> {
    return this.http.post("localidade/municipio", {
      cnomemun,
      cuf,
    });
  }

  setNovoEndereco(localidade: any): Observable<any> {
    return this.http.post("localidade/novoendereco", {
      localidade,
    });
  }
}
