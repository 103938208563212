import { Component, OnInit, Input } from "@angular/core";
import { MotoristaService } from "../../../../../shared/services/motorista.service";

@Component({
  selector: "visualizar-motoristas",
  templateUrl: "./visualizar-motoristas.component.html",
})
export class VisualizarMotoristasComponent implements OnInit {
  motorista: any = {};
  conteudoCarregado = false;

  arrTransacoesMotoristas: any = [];

  idEsp: number;
  tipoEsp: string;

  vizualizarTransacao = false;

  @Input() id: string;
  @Input() idcliente: string;

  showModal = false;

  constructor(private motoristaService: MotoristaService) {}

  ngOnInit(): any {
    this.motoristaService
      .getMotoristas({ id: this.id, idcliente: this.idcliente })
      .subscribe((response) => {
        if (response.success) {
          this.motorista = response.motorista;
          if (
            this.motorista.nrdvalidade != "" &&
            this.motorista.nrdvalidade != null
          ) {
            let dateformat =
              this.motorista.nrdvalidade.substring(8, 10) +
              "/" +
              this.motorista.nrdvalidade.substring(5, 7) +
              "/" +
              this.motorista.nrdvalidade.substring(0, 4);
            this.motorista.nrdvalidadeC = dateformat;
          }
        }

        this.conteudoCarregado = true;
      });

    this.motoristaService
      .getTransacoesMotorista({ idmotorista: this.id })
      .subscribe((response) => {
        if (response.success) {
          this.arrTransacoesMotoristas = response.transacoes;
        }
      });
  }

  convertBlob(arquivos_dado, arquivos_type): any {
    let binary = atob(arquivos_dado.replace(/\s/g, ""));
    let len = binary.length;
    let buffer = new ArrayBuffer(len);
    let view = new Uint8Array(buffer);
    for (let i = 0; i < len; i++) {
      view[i] = binary.charCodeAt(i);
    }

    let blob = new Blob([view], { type: arquivos_type });
    return URL.createObjectURL(blob);
  }

  abrirAnexo(titulo, arquivos_dado, arquivos_type) {
    const downloadLink = document.createElement("a");
    const fileName = titulo;

    downloadLink.setAttribute("type", "hidden");

    downloadLink.href = this.convertBlob(arquivos_dado, arquivos_type);
    downloadLink.download = fileName;

    document.body.appendChild(downloadLink);

    downloadLink.click();
    downloadLink.remove();
  }

  abrirModalTransacao(id: number, tipo: string): void {
    this.idEsp = id;

    switch (tipo) {
      case "1":
        tipo = "A";
        break;
      case "2":
        tipo = "I";
        break;
      case "3":
        tipo = "L";
        break;
      case "4":
        tipo = "R";
        break;
    }

    this.tipoEsp = tipo;

    this.vizualizarTransacao = true;

    this.showModal = true;
  }
}
