import {
  Component,
  Output,
  EventEmitter,
  ViewChild,
  ElementRef,
  OnInit,
  Input,
} from "@angular/core";
import { VeiculoService } from "src/app/modulos/veiculos/veiculo.service";

@Component({
  selector: "busca-placa",
  templateUrl: "./busca-placa.component.html",
})
export class BuscaPlacaComponent implements OnInit {
  filtros = {
    idcliente: "",
    codigo: "",
    modelo: "",
    marca: "",
    tipoVeiculo: "",
  };

  arrVeiculos: any;
  carregarConteudo = false;

  @Output() closeBusca = new EventEmitter<any>();
  @Input() idcliente: string;
  @Output() placa = new EventEmitter<string>();

  constructor(private veiculosService: VeiculoService) {}

  ngOnInit() {
    this.filtros.idcliente = this.idcliente;
  }

  selecionar(placa: any): void {
    this.placa.emit(placa);
  }

  onCloseModal(): void {
    this.closeBusca.emit();
  }

  busca(): void {
    this.carregarConteudo = true;

    this.veiculosService
      .getVeiculosModal(this.filtros)
      .subscribe((response) => {
        this.arrVeiculos = response.veiculos;
        this.carregarConteudo = false;
      });
  }
}
