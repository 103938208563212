import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class DescontoService {
  constructor(private http: HttpClient) { }

  getDesconto(filtros: any): Observable<any> {
    return this.http.get('desconto', {
      params: filtros,
    });
  }

  setDescontoClienteCredenciado(
    credenciado: any,
    desconto: any
  ): Observable<any> {
    return this.http.post('desconto/salvardesconto', {
      credenciado: credenciado,
      desconto: desconto,
    });
  }

  excluirDesconto(excluirDesconto: any): Observable<any> {
    return this.http.put('desconto/excluirdesconto', {
      excluirDesconto,
    });
  }
}
