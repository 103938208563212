import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "formatFone",
})
export class FoneBrPipe implements PipeTransform {
  transform(fone: any, args?: any): any {
    let dateformat = "";

    if (fone == undefined) {
      return dateformat;
    } else {
      if (fone.length > 10) {
        args = "(XX) XXXXX-XXXX";
      } else if (fone.length == 8) {
        args = "XXXX-XXXX";
      } else {
        args = "(XX) XXXX-XXXX";
      }

      if (args == "(XX) XXXX-XXXX") {
        fone = fone.replace("(", "");
        fone = fone.replace(")", "");
        fone = fone.replace(" ", "");
        fone = fone.replace("-", "");
        dateformat =
          "(" +
          fone.substring(0, 2) +
          ") " +
          fone.substring(2, 6) +
          "-" +
          fone.substring(6, 10);

        return dateformat;
      }

      if (args == "(XX) XXXXX-XXXX") {
        fone = fone.replace("(", "");
        fone = fone.replace(")", "");
        fone = fone.replace(" ", "");
        fone = fone.replace("-", "");

        dateformat =
          "(" +
          fone.substring(0, 2) +
          ") " +
          fone.substring(2, 7) +
          "-" +
          fone.substring(7, 11);

        return dateformat;
      }

      if (args == "XXXX-XXXX") {
        fone = fone.replace(" ", "");
        fone = fone.replace("-", "");
        dateformat = fone.substring(0, 4) + "-" + fone.substring(4, 8);

        return dateformat;
      }
    }
  }
}
