// Angular
import { Pipe, PipeTransform } from '@angular/core';

/**
 * Returns only first letter of string
 */
@Pipe({
  name: 'firstLetterUpper',
})
export class FirstLetterUpperPipe implements PipeTransform {
  /**
   * Transform
   *
   * @param value: any
   * @param args: any
   */
  transform(value: string, args?: any): any {
    let val = value;

    return value.substring(0, 1).toUpperCase() + val.substring(1);
  }
}
