import { Component, OnInit, Input } from '@angular/core';
import { SpecsVeiculoDetalhe } from '../../models/veiculo-detalhe.model';

@Component({
  selector: 'app-especificacoes',
  templateUrl: './especificacoes.component.html',
  styleUrls: ['./../../detalhes-veiculo.component.css']
})
export class EspecificacoesComponent implements OnInit {
  @Input() specsData: SpecsVeiculoDetalhe[];
  
  constructor() { }

  ngOnInit() {
  }

}
