import { Component, OnInit } from "@angular/core";
import { SistemaService } from "src/app/modulos/shared/services/sistema.service";

@Component({
  selector: "app-footer",
  templateUrl: "./footer.component.html",
  styleUrls: ["./footer.component.scss"],
})
export class AppFooterComponent implements OnInit {
  copyRightDate = new Date().getFullYear();
  version = "";

  constructor(private readonly sistemaService: SistemaService) {}

  ngOnInit(): void {
    this.sistemaService.getVersaoSistema().subscribe({
      next: (response) => {
        let version = localStorage.getItem("version");
        if (version != response.versao) {
          location.href =
            location.href.split("?")[0] +
            "?forceReload=" +
            new Date().getTime();
          location.reload();
        }
        localStorage.setItem("version", response.versao);
        this.version = response.versao;
      },
    });
  }
}
