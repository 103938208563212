import { Component, OnInit } from "@angular/core";
import { Chart } from "angular-highcharts";
import { DesempenhoService } from "../../../services/desempenho.service";

@Component({
  selector: "line-char-data-qntdveiculos",
  templateUrl: "./line-char-data.html",
  styleUrls: ["./line-char-data.css"],
})
export class LineChartDataQntdVeiculoComponent implements OnInit {
  charta: any;

  lineChartColors = [
    "rgba(159,159,159,0.8)",
    "rgba(54,148,244,0.7)",
    "rgba(184,244,54,0.7)",
    "rgba(244,170,54,0.7)",
  ];

  graficoAtivo = false;

  constructor(private desempenhoService: DesempenhoService) {}

  ngOnInit(): void {
    this.defineFilterChanger();
  }

  defineFilterChanger(): void {
    this.desempenhoService
      .getInformativoRedeQntdVeiculo()
      .subscribe((response) => {
        for (let i = 0; i < response.qntdVeiculo.length; i++) {
          for (let j = 0; j < response.qntdVeiculo[i].data.length; j++) {
            response.qntdVeiculo[i].data[j] *= 1;
          }

          response.qntdVeiculo[i].name = response.qntdVeiculo[i].label;
          response.qntdVeiculo[i].color = this.lineChartColors[i];
        }

        this.charta = new Chart(<any>{
          chart: {
            type: "column",
            height: 300,
          },
          title: {
            text: "Quantidade de Veículo por Familia",
          },
          credits: {
            enabled: false,
          },
          series: response.qntdVeiculo,
          yAxis: {
            min: 0,
            title: {
              text: "",
            },
          },
          xAxis: {
            categories: [
              "Janeiro",
              "Fevereiro",
              "Março",
              "Abril",
              "Maio",
              "Junho",
              "Julho",
              "Agosto",
              "Setembro",
              "Outubro",
              "Novembro",
              "Dezembro",
            ],
            crosshair: true,
            labels: {
              x: -10,
            },
          },
          legend: {
            layout: "vertical",
            align: "left",
            verticalAlign: "top",
            y: 40,
            itemStyle: {
              "font-size": "9px",
            },
          },
          plotOptions: {
            column: {
              pointPadding: 0.1,
              borderWidth: 0,
            },
          },
          responsive: {
            rules: [
              {
                condition: {
                  maxWidth: 500,
                },
                chartOptions: {
                  legend: {
                    enabled: false,
                  },
                },
              },
            ],
          },
          exporting: {
            enabled: false,
          },
        });

        this.graficoAtivo = true;

        setTimeout(() => {
          try {
            this.charta.ref.reflow();
          } catch (e) {}
        }, 100);
      });
  }

  Popup() {
    this.charta.ref$.subscribe((ref) => {
      let svg = ref.getSVG();

      let mywindow = window.open("", "my div", "height=400,width=700");
      mywindow.document.write(
        "<html><head><title>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</title>"
      );
      mywindow.document.write("</head><body>");
      mywindow.document.write("" + svg + "");
      mywindow.document.write("</body></html>");

      mywindow.document.close(); // necessary for IE >= 10
      mywindow.focus(); // necessary for IE >= 10

      setTimeout(() => {
        mywindow.print();
        mywindow.close();
      }, 10);
    });
  }
}
