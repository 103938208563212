import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { UserService } from '../../../shared/services/user.service';

@Component({
  selector: 'regras-perfil-abastecimento',
  templateUrl: './regras-perfil.component.html'
})

export class RegrasPerfilComponent implements OnInit {
  evento = 1;

  carrPes = false;

  id: string;
  idcliente = '0';
  idrelacionado = '0';

  telaPesquisa = true;
  telaCadastrar = false;
  telaAlterar = false;
  telaAlterarRegras = false;

  centrocusto = '0';

  private sub: any;

  alterarDados = true;

  constructor(private route: ActivatedRoute,
    private userService: UserService) {
    this.alterarDados = this.userService.alterarDados;
  }

  ngOnInit(): void {
    this.sub = this.route.params.subscribe(params => {
      this.idcliente = params['idcliente'];
    });
  }

  cancelarTela(event: any): void {
    this.telaPesquisa = true;
    this.telaCadastrar = false;
    this.telaAlterar = false;
    this.telaAlterarRegras = false;

    if (event > 0 && this.carrPes) {
      this.evento *= -1;
    }
  }

  pesquisar(): void {
    this.telaPesquisa = true;
    this.telaCadastrar = false;
    this.telaAlterar = false;
    this.telaAlterarRegras = false;

    if (this.carrPes) {
      this.evento *= -1;
    }
  }

  cadastrar(): void {
    this.telaPesquisa = false;
    this.telaCadastrar = true;
    this.telaAlterar = false;
    this.telaAlterarRegras = false;
  }

  carregadoPesquisa(event: any) {
    this.carrPes = event;
  }

  editar(event: any) {
    if (event != '' && event != undefined) {
      this.id = event;
    }

    this.telaPesquisa = false;
    this.telaCadastrar = false;
    this.telaAlterar = true;
    this.telaAlterarRegras = false;
  }

  editarRegras(event: any) {
    this.idrelacionado = event.split(';')[0];

    this.centrocusto = event.split(';')[1];
    this.id = event;

    this.telaPesquisa = false;
    this.telaCadastrar = false;
    this.telaAlterar = false;
    this.telaAlterarRegras = true;
  }
}
