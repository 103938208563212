import {Component, Input} from '@angular/core';

@Component({
  selector: 'informativo-redes',
  templateUrl: './informativo.redes.component.html',
  styleUrls: ['./informativo.redes.component.css']
})
export class InformativoRedesComponent {
  @Input('modulo') modulo = 1;
}
