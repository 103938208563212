import { Component, Input, OnInit } from '@angular/core';
import { Chart } from 'angular-highcharts';
import { DesempenhoService } from '../../../services/desempenho.service';

@Component({
  selector: 'line-char-data-consumo',
  templateUrl: './line-char-data.html',
  styleUrls: ['./line-char-data.css'],
})
export class LineChartDataConsumoComponent implements OnInit {
  charta: any;
  graficoAtivo = false;

  @Input() modulo: number;

  constructor(private desempenhoService: DesempenhoService) { }

  ngOnInit(): void {
    this.defineFilterChanger();
  }

  defineFilterChanger(): void {
    this.desempenhoService
      .getInformativoRedeConsumo({ modulo: this.modulo })
      .subscribe((response) => {
        if (response.success) {
          response.consumo = this.mountConsumoGraphData(response.consumo);
          response.consumobombainterna = this.mountConsumoGraphData(
            response.consumobombainterna
          );

          this.charta = new Chart({
            chart: {
              type: 'column',
              height: 300,
            },
            title: {
              text: 'Quantidade de Consumo',
            },
            credits: {
              enabled: false,
            },
            series: [
              {
                data: response.consumo,
                name: 'Consumo',
                color: 'rgba(54,148,244,0.8)',
              },
              {
                data: response.consumobombainterna,
                name: 'Consumo bomba interna',
                color: 'rgba(244,170,54,0.7)',
              },
            ],
            yAxis: {
              min: 0,
              title: {
                text: '',
              },
            },
            xAxis: {
              categories: [
                'Janeiro',
                'Fevereiro',
                'Março',
                'Abril',
                'Maio',
                'Junho',
                'Julho',
                'Agosto',
                'Setembro',
                'Outubro',
                'Novembro',
                'Dezembro',
              ],
              crosshair: true,
              labels: {
                x: -10,
              },
            },
            legend: {
              enabled: false,
            },
            plotOptions: {
              column: {
                pointPadding: 0.1,
                borderWidth: 0,
              },
            },
            responsive: {
              rules: [
                {
                  condition: {
                    maxWidth: 500,
                  },
                  chartOptions: {
                    legend: {
                      enabled: false,
                    },
                  },
                },
              ],
            },
            exporting: {
              enabled: false,
            },
          } as any);

          this.graficoAtivo = true;
        }
      });
  }

  mountConsumoGraphData(data: any[]) {
    let newArray = [];
    for (let monthIndex = 0; monthIndex < 12; monthIndex++) {
      let result = 0;

      if (data[monthIndex]) {
        const item = data[monthIndex];
        result = item.total * 1;
      }

      newArray.push(result);
    }
    return newArray;
  }

  Popup() {
    this.charta.ref$.subscribe((ref) => {
      let svg = ref.getSVG();

      let mywindow = window.open('', 'my div', 'height=400,width=700');
      mywindow.document.write(
        '<html><head><title>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</title>'
      );
      mywindow.document.write('</head><body>');
      mywindow.document.write('' + svg + '');
      mywindow.document.write('</body></html>');

      mywindow.document.close(); // necessary for IE >= 10
      mywindow.focus(); // necessary for IE >= 10

      setTimeout(() => {
        mywindow.print();
        mywindow.close();
      }, 10);
    });
  }
}
