import { Component, Input, OnInit } from '@angular/core';

@Component({
  selector: 'historico-manutencao',
  templateUrl: './historico-manutencao.component.html',
  styleUrls: ['./../../visualizar-veiculos.component.css'],
})
export class HistoricoManutencaoComponent implements OnInit {
  @Input('arrVeiculoManutencao') arrVeiculoManutencao: any;

  constructor() {}

  ngOnInit(): void {}
}
