// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `.loadingTela {
  width: 100%;
  text-align: center;
}
`, "",{"version":3,"sources":["webpack://./src/app/modulos/shared/core/informativo/informativo-cliente-manu/informativo-cliente-manu.component.css"],"names":[],"mappings":"AAAA;EACE,WAAW;EACX,kBAAkB;AACpB","sourcesContent":[".loadingTela {\n  width: 100%;\n  text-align: center;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
