import { Component, Input, OnInit } from "@angular/core";
import { Chart } from "angular-highcharts";
import * as moment from "moment";

@Component({
  selector: "informativo-veiculo",
  templateUrl: "./informativo-veiculo.component.html",
  styleUrls: ["./../../visualizar-veiculos.component.css"],
})
export class InformativoVeiculoComponent implements OnInit {
  @Input("sistema") sistema: any = 1;
  @Input("GNVMark") GNVMark = 0;
  @Input("idveiculo") idveiculo: number;
  @Input("arrVeiculos") arrVeiculos: any;
  @Input("arrCartaoVeiculos") arrCartaoVeiculos: any;
  @Input("arrCartaoVeiculosManutencao") arrCartaoVeiculosManutencao: any;
  @Input("veiculoEvolucaoConsumo") veiculoEvolucaoConsumo: any[] = [];

  chart: Chart;

  constructor() {}

  ngOnInit(): void {
    let arrCategories: any[] = [];
    let arrData: any[] = [];

    this.veiculoEvolucaoConsumo.forEach((item) => {
      const date = new Date(item.valor);
      const qtd = parseFloat(item.qtd);
      arrCategories.push(moment(date).format("DD/MM/yyyy"));
      arrData.push(qtd);
    });

    this.chart = new Chart({
      title: {
        text: "",
      },
      xAxis: {
        categories: arrCategories,
      },
      yAxis: {
        title: {
          text: "Média Km/L (M3)",
        },
        plotLines: [
          {
            value: 0,
            width: 1,
            color: "#808080",
          },
        ],
      },
      tooltip: {
        valueSuffix: " Km/L",
      },
      legend: {
        layout: "vertical",
        align: "right",
        verticalAlign: "middle",
        borderWidth: 0,
        itemStyle: {
          "font-size": "9px",
        },
      },
      series: [
        {
          type: "line",
          name: "Consumo",
          data: arrData,
        },
      ],
      credits: {
        enabled: false,
      },
    });
  }
}
