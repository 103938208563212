import {
  Component,
  Input,
  Output,
  EventEmitter,
  OnInit,
  ViewChild,
  ElementRef,
} from "@angular/core";
import { UsuariosService } from "../../../services/usuarios.service";
import Swal from "sweetalert2";

@Component({
  selector: "visualizar-usuarios",
  templateUrl: "./visualizar-usuarios.component.html",
})
export class VisualizarUsuariosComponent implements OnInit {
  @Input() tipo: number;
  @Input() idrelacionado: number;
  @Input() id: number;

  arrPerfis: any = [];
  conteudoCarregado = false;
  conteudoCarregadoPerfil = false;
  conteudoCarregadoUsuario = false;

  usuario: any = {
    cgc: "",
    nome: "",
    email: "",
    status: "",
    idperfil: "",
    perfil: "",
  };

  @Output() cancelar = new EventEmitter<number>();

  @ViewChild("centrocusto") centrocusto: ElementRef;

  constructor(private usuariosService: UsuariosService) {}

  voltarPesquisa(): void {
    this.cancelar.emit(0);
  }

  ngOnInit() {
    this.usuariosService
      .getPerfis({ tipo: this.tipo, idrelacionado: this.idrelacionado })
      .subscribe((response) => {
        if (response.success) {
          this.arrPerfis = response.perfis;
        }

        this.conteudoCarregadoPerfil = true;
        this.verificarConteudoCarregado();
      });

    this.usuariosService
      .getUsuario({
        tipo: this.tipo,
        idrelacionado: this.idrelacionado,
        id: this.id,
      })
      .subscribe((response) => {
        if (response.success) {
          this.usuario = response.usuario;
        }

        this.conteudoCarregadoUsuario = true;
        this.verificarConteudoCarregado();
      });
  }

  verificarConteudoCarregado(): void {
    if (this.conteudoCarregadoPerfil && this.conteudoCarregadoUsuario) {
      this.conteudoCarregado = true;
    } else {
      this.conteudoCarregado = false;
    }
  }

  salvar(usuario: any): void {
    Swal.fire({
      title: "Alterar usuario?",
      text: "Você está prestes a alterar um usuario",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sim, atualize!",
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return this.usuariosService.attUsuario(
          usuario,
          this.tipo,
          this.idrelacionado
        );
      },
    }).then((result) => {
      if (result.isConfirmed) {
        if (result.value.success) {
          Swal.fire("", "Usuario atualizado com sucesso!", "success").then(
            () => {
              this.cancelar.emit(1);
            }
          );
        } else {
          Swal.fire("", result.value.message, "error");
        }
      }
    });
  }
}
