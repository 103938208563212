import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

@Injectable()
export class SistemaService {
  constructor(private http: HttpClient) {}

  getSistemas(filtros: any): Observable<any> {
    return this.http.get("sistema", {
      params: filtros,
    });
  }

  saveSistemas(dados: any): Observable<any> {
    return this.http.post("sistema/salvar", dados);
  }

  getVersaoSistema(): Observable<any> {
    return this.http.get("versaoSistema");
  }
}
