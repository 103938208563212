import { Component} from "@angular/core";


@Component({
  selector: "app-consultas-credenciados",
  templateUrl: "./consulta-credenciados.component.html",
  styleUrls: ["./consulta-credenciados.component.css"],
})
export class ConsultasCredenciadosComponent {
  
}
