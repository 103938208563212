import { CommonModule, DatePipe, DecimalPipe } from "@angular/common";
import { HTTP_INTERCEPTORS } from "@angular/common/http";
import { NgModule } from "@angular/core";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { MatAutocompleteModule } from "@angular/material/autocomplete";
import { MatButtonModule } from "@angular/material/button";
import { MatCheckboxModule } from "@angular/material/checkbox";
import { MatNativeDateModule } from "@angular/material/core";
import { MatDialogModule } from "@angular/material/dialog";
import { MatFormFieldModule } from "@angular/material/form-field";
import { MatIconModule } from "@angular/material/icon";
import { MatInputModule } from "@angular/material/input";
import { MatProgressSpinnerModule } from "@angular/material/progress-spinner";
import { MatSelectModule } from "@angular/material/select";
import { MatTabsModule } from "@angular/material/tabs";
import { MatTooltipModule } from "@angular/material/tooltip";
import { RouterModule } from "@angular/router";
import { CollapseModule, DropdownModule } from "@coreui/angular";
import {
  NgbPaginationModule,
  NgbPopoverModule,
} from "@ng-bootstrap/ng-bootstrap";
import { SweetAlert2Module } from "@sweetalert2/ngx-sweetalert2";
import { AngularDualListBoxModule } from "angular-dual-listbox";
import { ChartModule, HIGHCHARTS_MODULES } from "angular-highcharts";
import * as more from "highcharts/highcharts-more.src";
import * as exportData from "highcharts/modules/export-data.src";
import * as exporting from "highcharts/modules/exporting.src";
import { CurrencyMaskModule } from "ng2-currency-mask";
import {
  IConfig,
  NgxMaskDirective,
  NgxMaskPipe,
  provideNgxMask,
} from "ngx-mask";
import { NgxMatSelectSearchModule } from "ngx-mat-select-search";
import { NgxPrintModule } from "ngx-print";
import { ConsultaService } from "../abastecimento/consultas-admin/componenteconsultas/consulta.service";
import { MessageEventFrota } from "../abastecimento/regras/messageevent.service";
import { AlterarPerfilRegrasComponent } from "../abastecimento/regras/regras-perfil/alterar/alterar-perfil-regras.component";
import { CadastrarPerfilRegrasComponent } from "../abastecimento/regras/regras-perfil/cadastrar/cadastrar-perfil-regras.component";
import { PesquisarPerfilRegrasComponent } from "../abastecimento/regras/regras-perfil/pesquisar/pesquisar-perfil-regras.component";
import { RegrasPerfilComponent } from "../abastecimento/regras/regras-perfil/regras-perfil.component";
import { RegrasComponent } from "../abastecimento/regras/regras.component";
import { RegraService } from "../abastecimento/regras/regras.service";
import { CentroCustoService } from "../clientes/visualizar/centro-custo/centro-custo.service";
import { VisualizarMotoristasComponent } from "../clientes/visualizar/pessoas/motoristas/visualizar/visualizar-motoristas.component";
import { InfoManutencaoService } from "../manutencao/info.manutencao.service";
import { RegrasManutencaoComponent } from "../manutencao/regras/regras.component";
import { CadastroComponent } from "../veiculos/detalhes/components/cadastro/cadastro.component";
import { EspecificacoesComponent } from "../veiculos/detalhes/components/especificacoes/especificacoes.component";
import { FipeComponent } from "../veiculos/detalhes/components/fipe/fipe.component";
import { RevisoesComponent } from "../veiculos/detalhes/components/revisoes/revisoes.component";
import { DetalhesVeiculoComponent } from "../veiculos/detalhes/detalhes-veiculo.component";
import { ConfiguracoesVeiculoComponent } from "../veiculos/visualizar/components/configuracoes-veiculo/configuracoes-veiculo.component";
import { HistoricoManutencaoComponent } from "../veiculos/visualizar/components/historico-manutencao/historico-manutencao.component";
import { HistoricoTransacoesComponent } from "../veiculos/visualizar/components/historico-transacoes/historico-transacoes.component";
import { InformativoVeiculoComponent } from "../veiculos/visualizar/components/informativo-veiculo/informativo-veiculo.component";
import { LogAjusteOdometroComponent } from "../veiculos/visualizar/components/log-ajuste-odometro/log-ajuste-odometro.component";
import { MotoristasGruposComponent } from "../veiculos/visualizar/components/motoristas-grupos/motoristas-grupos.component";
import { TanquesCombustiveisComponent } from "../veiculos/visualizar/components/tanques-combustiveis/tanques-combustiveis.component";
import { VisualizarVeiculosComponent } from "../veiculos/visualizar/visualizar-veiculos.component";
import { BaseModalComponent } from "./components/base-modal/base-modal.component";
import { BuscaClientesComponent } from "./components/busca-cliente/busca-cliente.component";
import { BuscaCredenciadoComponent } from "./components/busca-credenciado/busca-credenciado.component";
import { BuscaPlacaComponent } from "./components/busca-placa/busca-placa.component";
import { LineChartDataClienteComponent } from "./components/graficos/base-chart/line-char-data-cliente";
import { LineChartDataConsumoComponent } from "./components/graficos/base-chart/line-char-data-consumo";
import { LineChartDataConsVeiculoComponent } from "./components/graficos/base-chart/line-char-data-consveiculo";
import { LineChartDataCredenciadoComponent } from "./components/graficos/base-chart/line-char-data-credenciado";
import { LineChartDataTGeralComponent } from "./components/graficos/base-chart/line-char-data-geral";
import { LineChartDataQntdVeiculoComponent } from "./components/graficos/base-chart/line-char-data-qntdveiculos";
import { LineChartDataTaxaComponent } from "./components/graficos/base-chart/line-char-data-taxas";
import { LineChartDataTicketMedioComponent } from "./components/graficos/base-chart/line-char-data-ticketmedio";
import { LineChartDataTransacaoComponent } from "./components/graficos/base-chart/line-char-data-transacao";
import { LineChartDataVeiculosComponent } from "./components/graficos/base-chart/line-char-data-veiculos";
import { LoadingComponent } from "./components/loading/loading.component";
import { LocalAutocompleteComponent } from "./components/local-autocomplete/local-autocomplete.component";
import { LocalidadeCadastroComponent } from "./components/localidade/localidade-cadastro.component";
import { LocalidadeComponent } from "./components/localidade/localidade.component";
import { SearchSelectComponent } from "./components/search-select/search-select.component";
import { LineChartDataConsumoVeiculoComponent } from "./core/desempenho/base-char/line-char-data-consumo-veiculo.component";
import { LineChartDataGeralComponent } from "./core/desempenho/base-char/line-char-data-geral.component";
import { LineChartDataMediaVeiculoComponent } from "./core/desempenho/base-char/line-char-data-media-veiculo.component";
import { LineChartDataPrecoMedioComponent } from "./core/desempenho/base-char/line-char-data-preco-medio.component";
import { DesempenhoComponent } from "./core/desempenho/desempenho.component";
import { DescontoFechamentosComponent } from "./components/desconto-fechamentos/desconto-fechamentos-component";
import { InformativoClienteManuComponent } from "./core/informativo/informativo-cliente-manu/informativo-cliente-manu.component";
import { InformativoClienteComponent } from "./core/informativo/informativo-cliente/informativo.cliente.component";
import { InformativoRedesComponent } from "./core/informativo/informativo-rede/informativo.redes.component";
import { InformativoComponent } from "./core/informativo/informativo.component";
import { ManualComponent } from "./core/manual/manual.component";
import { StimulsoftReportComponent } from "./core/report/stimulsoft-report";
import { PesquisarTransacoesComponent } from "./core/transacoes/pesquisar/pesquisar-transacoes.component";
import { TransacoesComponent } from "./core/transacoes/transacoes.component";
import { VisualizarTransacoesComponent } from "./core/transacoes/visualizar/visualizar-transacoes.component";
import { AlterarUsuariosComponent } from "./core/usuarios/alterar/alterar-usuarios.component";
import { CadastrarUsuariosComponent } from "./core/usuarios/cadastrar/cadastrar-usuarios.component";
import { PesquisarUsuariosComponent } from "./core/usuarios/pesquisar/pesquisar-usuarios.component";
import { UsuariosComponent } from "./core/usuarios/usuarios.component";
import { VisualizarUsuariosComponent } from "./core/usuarios/visualizar/visualizar-usuarios.component";
import { CartaoPipe } from "./pipes/cartao.pipe";
import { CepPipe } from "./pipes/cep.pipe";
import { CgcPipe } from "./pipes/cgc.pipe";
import { CortarLabelPipe } from "./pipes/cortarLabel.pipe";
import { DateBrPipe } from "./pipes/datebr.pipe";
import { FilterProdutosPipe } from "./pipes/filter-produtos.pipe";
import { FiltrarVeiculosPipe } from "./pipes/filterveiculospipe";
import { FirstLetterUpperPipe } from "./pipes/first-letter-upper.pipe";
import { FirstLetterPipe } from "./pipes/first-letter.pipe";
import { FoneBrPipe } from "./pipes/fonebr.pipe";
import { OleosPipe } from "./pipes/oleos.pipe";
import { PlacaPipe } from "./pipes/placa.pipe";
import { ProdutosPipe } from "./pipes/produtos.pipe";
import { RealPipe } from "./pipes/real.pipe";
import { SafePipe } from "./pipes/safe.pipe";
import { SubstringPipe } from "./pipes/substring.pipe";
import { TimePipe } from "./pipes/time.pipe";
import { UpperPipe } from "./pipes/upper.pipe";
import { BroadCastService } from "./services/broadcast.service";
import { EmpresaService } from "./services/empresa.service";
import { FechamentoService } from "./services/fechamento.service";
import { InterceptService } from "./services/intercept.service";
import { LocalidadeService } from "./services/localidade.service";
import { PublicoService } from "./services/publico.service";
import { TransacaoService } from "./services/transacao.service";
import { UsuariosService } from "./services/usuarios.service";
import { MatRadioModule } from "@angular/material/radio";
import { SidebarNotifyService } from "./services/sidebar-notify.service";
import { RequiredDirective } from "./directives/required.directive";
import { FilterTaxaModulos } from "./pipes/filter-TaxaModulos.pipe";
import { SelectCodtnComponent } from "./components/select-codtn/select-codtn.component";
import { BaseReportComponent } from "./components/base-report/base-report.component";
import { TransacaoWebComponent } from "./core/transacao-web/transacao-web.component";
import { ConsultasCredenciadosComponent } from "./components/consultas-cred/consulta-credenciados.component";
import { SelecionarComponent } from "./components/consultas-cred/componentes/selecionar/selecionar.component";
import { ConsultasDescontoClienteCredenciadosComponent } from "./components/consultas-cred/componentes/desconto-cliente/consulta-desconto-cliente-credenciado.component";
import { CredenciadoService } from "../credenciados/services/credenciado.service";
import { DescontoService } from "../clientes/visualizar/desconto/desconto.service";
import { CartaCircularizacaoComponent } from "./components/consultas-cred/componentes/carta-circularizacao/carta-circularizacao.component";
import { ConsultaFechamentosComponent } from "./components/consultas-cred/componentes/fechamentos/consulta-fechamentos.component";
import { VisualizarTransacoesManutencaoComponent } from "./core/transacoes/visualizar-manutencao/visualizar-transacoes-manutencao.component";
import { LogAlteracoesVeiculoComponent } from "../veiculos/visualizar/components/log-alteracoes/log-alteracoes.component";
import { TransacoesCredenciadosComponent } from "./components/consultas-cred/componentes/transacoes-credenciados/transacoes-credenciados.component";
import { LogAlteracoesRegrasComponent } from "../abastecimento/regras/log-alteracoes-regras/log-alteracoes-regras.component";

const maskConfig: Partial<IConfig> = {
  validation: false,
};

@NgModule({
  imports: [
    CommonModule,
    RouterModule,
    FormsModule,
    ChartModule,
    MatTabsModule,
    MatAutocompleteModule,
    MatIconModule,
    MatProgressSpinnerModule,
    MatButtonModule,
    MatDialogModule,
    MatFormFieldModule,
    MatInputModule,
    MatTooltipModule,
    MatNativeDateModule,
    MatSelectModule,
    MatCheckboxModule,

    NgxMatSelectSearchModule,

    ReactiveFormsModule,
    NgbPaginationModule,
    NgbPopoverModule,
    FormsModule,
    NgxPrintModule,
    NgxMaskDirective,
    NgxMaskPipe,
    CurrencyMaskModule,

    CollapseModule,
    DropdownModule,
    AngularDualListBoxModule,
    MatRadioModule,
    DecimalPipe,

    SweetAlert2Module.forRoot(),
  ],
  declarations: [
    TransacoesCredenciadosComponent,
    LogAlteracoesVeiculoComponent,
    VisualizarTransacoesManutencaoComponent,
    RequiredDirective,
    ConsultasCredenciadosComponent,
    SelecionarComponent,
    ConsultasDescontoClienteCredenciadosComponent,
    CartaCircularizacaoComponent,
    ConsultaFechamentosComponent,
    LocalAutocompleteComponent,
    CartaoPipe,
    CepPipe,
    CgcPipe,
    CortarLabelPipe,
    DateBrPipe,
    FilterProdutosPipe,
    FilterTaxaModulos,
    FiltrarVeiculosPipe,
    FoneBrPipe,
    OleosPipe,
    PlacaPipe,
    TimePipe,
    ProdutosPipe,
    RealPipe,
    SafePipe,
    SubstringPipe,
    UpperPipe,
    FirstLetterPipe,
    FirstLetterUpperPipe,
    LoadingComponent,
    SearchSelectComponent,
    SelectCodtnComponent,

    LineChartDataClienteComponent,
    LineChartDataConsumoComponent,
    LineChartDataConsVeiculoComponent,
    LineChartDataCredenciadoComponent,
    LineChartDataTGeralComponent,
    LineChartDataQntdVeiculoComponent,
    LineChartDataTaxaComponent,
    LineChartDataTicketMedioComponent,
    LineChartDataTransacaoComponent,
    LineChartDataVeiculosComponent,

    BuscaClientesComponent,
    BuscaCredenciadoComponent,
    BuscaPlacaComponent,

    LocalidadeComponent,
    LocalidadeCadastroComponent,

    InformativoVeiculoComponent,
    ConfiguracoesVeiculoComponent,
    TanquesCombustiveisComponent,
    MotoristasGruposComponent,
    HistoricoTransacoesComponent,
    LogAjusteOdometroComponent,
    HistoricoManutencaoComponent,
    VisualizarVeiculosComponent,
    DetalhesVeiculoComponent,

    TransacoesComponent,
    PesquisarTransacoesComponent,
    VisualizarTransacoesComponent,
    DescontoFechamentosComponent,

    StimulsoftReportComponent,

    InformativoComponent,
    InformativoClienteComponent,
    InformativoRedesComponent,

    DesempenhoComponent,
    LineChartDataPrecoMedioComponent,
    LineChartDataMediaVeiculoComponent,
    LineChartDataGeralComponent,
    LineChartDataConsumoVeiculoComponent,

    RegrasComponent,
    LogAlteracoesRegrasComponent,
    RegrasPerfilComponent,
    PesquisarPerfilRegrasComponent,
    CadastrarPerfilRegrasComponent,
    AlterarPerfilRegrasComponent,

    RegrasManutencaoComponent,

    UsuariosComponent,
    AlterarUsuariosComponent,
    CadastrarUsuariosComponent,
    PesquisarUsuariosComponent,
    VisualizarUsuariosComponent,

    VisualizarMotoristasComponent,
    ManualComponent,
    InformativoClienteManuComponent,

    FipeComponent,
    CadastroComponent,
    RevisoesComponent,
    EspecificacoesComponent,
    BaseModalComponent,
    SelectCodtnComponent,
    BaseReportComponent,

    TransacaoWebComponent,
  ],
  exports: [
    TransacoesCredenciadosComponent,
    VisualizarTransacoesManutencaoComponent,
    ConsultasCredenciadosComponent,
    SelecionarComponent,
    ConsultasDescontoClienteCredenciadosComponent,
    CartaCircularizacaoComponent,
    ConsultaFechamentosComponent,
    NgxMaskDirective,
    NgxMaskPipe,
    SearchSelectComponent,
    MatTabsModule,
    BaseModalComponent,
    ChartModule,
    NgbPaginationModule,
    NgbPopoverModule,
    NgxPrintModule,
    BaseReportComponent,

    DecimalPipe,

    AngularDualListBoxModule,

    MatIconModule,
    MatDialogModule,
    MatProgressSpinnerModule,
    MatButtonModule,
    MatAutocompleteModule,
    MatFormFieldModule,
    MatInputModule,
    MatTooltipModule,
    MatSelectModule,
    MatCheckboxModule,

    NgxMatSelectSearchModule,

    FormsModule,
    ReactiveFormsModule,

    CartaoPipe,
    CepPipe,
    CgcPipe,
    CortarLabelPipe,
    DateBrPipe,
    FilterProdutosPipe,
    FilterTaxaModulos,
    FiltrarVeiculosPipe,
    FoneBrPipe,
    OleosPipe,
    PlacaPipe,
    ProdutosPipe,
    RealPipe,
    SafePipe,
    SubstringPipe,
    TimePipe,
    UpperPipe,
    FirstLetterPipe,
    FirstLetterUpperPipe,
    LoadingComponent,
    SelectCodtnComponent,

    LineChartDataClienteComponent,
    LineChartDataConsumoComponent,
    LineChartDataConsVeiculoComponent,
    LineChartDataCredenciadoComponent,
    LineChartDataTGeralComponent,
    LineChartDataQntdVeiculoComponent,
    LineChartDataTaxaComponent,
    LineChartDataTicketMedioComponent,
    LineChartDataTransacaoComponent,
    LineChartDataVeiculosComponent,
    MatRadioModule,

    BuscaClientesComponent,
    BuscaCredenciadoComponent,
    BuscaPlacaComponent,

    LocalidadeComponent,
    LocalidadeCadastroComponent,

    LocalAutocompleteComponent,

    VisualizarVeiculosComponent,
    DetalhesVeiculoComponent,

    TransacoesComponent,
    PesquisarTransacoesComponent,
    VisualizarTransacoesComponent,
    DescontoFechamentosComponent,

    StimulsoftReportComponent,

    InformativoComponent,
    InformativoClienteComponent,
    InformativoRedesComponent,

    DesempenhoComponent,
    LineChartDataPrecoMedioComponent,
    LineChartDataMediaVeiculoComponent,
    LineChartDataGeralComponent,
    LineChartDataConsumoVeiculoComponent,

    RegrasComponent,
    RegrasPerfilComponent,
    PesquisarPerfilRegrasComponent,
    CadastrarPerfilRegrasComponent,
    AlterarPerfilRegrasComponent,

    RegrasManutencaoComponent,

    UsuariosComponent,
    AlterarUsuariosComponent,
    CadastrarUsuariosComponent,
    PesquisarUsuariosComponent,
    VisualizarUsuariosComponent,

    VisualizarMotoristasComponent,
    ManualComponent,
    InformativoClienteManuComponent,

    FipeComponent,
    CadastroComponent,
    RevisoesComponent,
    EspecificacoesComponent,

    RequiredDirective,

    CollapseModule,
    DropdownModule,

    SweetAlert2Module,

    CurrencyMaskModule,

    TransacaoWebComponent,
  ],
  providers: [
    provideNgxMask(maskConfig),
    {
      provide: HIGHCHARTS_MODULES,
      useFactory: () => [more, exporting, exportData],
    },
    LocalidadeService,
    CgcPipe,
    EmpresaService,
    TransacaoService,
    UsuariosService,
    FechamentoService,
    CentroCustoService,
    PublicoService,
    RegraService,
    MessageEventFrota,
    BroadCastService,
    ConsultaService,
    InfoManutencaoService,
    DatePipe,
    RealPipe,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: InterceptService,
      multi: true,
    },
    SidebarNotifyService,
    DecimalPipe,
    CredenciadoService,
    DescontoService,
  ],
})
export class SharedModule {}
