import { Observable } from "rxjs";
import { Injectable } from "@angular/core";
import { HttpClient } from "@angular/common/http";

@Injectable()
export class ClienteService {
  cliente: any;
  clienteConsulta: any;
  clienteRelatorio: any;
  clienteChecklist: number;

  idcliente = "0";
  taxaAdmPrePago = 0;

  constructor(private http: HttpClient) {
    this.verificaSession();
  }

  verificaSession(): void {
    if (localStorage.getItem("dadosCliente")) {
      this.cliente = JSON.parse(localStorage.getItem("dadosCliente"));
      this.clienteChecklist = this.cliente.checklist;
      this.idcliente = this.cliente.idcliente;
    }
  }

  logout(): void {
    this.cliente = undefined;
    this.clienteConsulta = undefined;
    this.clienteRelatorio = undefined;
    this.idcliente = "0";
  }

  getElementosAtivosCliente(idcliente: number): Observable<any> {
    return this.http.get("cliente/metodo/controleusuariosativos", {
      params: { idcliente },
    });
  }

  getTipoTaxas(): Observable<any> {
    return this.http.get("cliente/tiposTaxa");
  }

  getObservacoes(filtros: any): Observable<any> {
    return this.http.get("cliente/metodos/getobservacoes", {
      params: filtros,
    });
  }

  getLogAlteracoes(filtros: any): Observable<any> {
    return this.http.get("cliente/metodos/getalteracoes", {
      params: filtros,
    });
  }

  getClienteHttp(idcliente: any): Observable<any> {
    return this.http.get("cliente/" + idcliente);
  }

  setClienteConsulta(cliente: any): void {
    this.clienteConsulta = cliente;
    this.clienteRelatorio = cliente;
  }

  setCliente(cliente: any): void {
    this.cliente = cliente;
  }

  getClienteConsulta(): any {
    return this.clienteConsulta;
  }

  getClienteRelatorio(): any {
    return this.clienteRelatorio;
  }

  getCliente(): any {
    return this.cliente;
  }

  getClienteChecklist(): number {
    return this.clienteChecklist;
  }

  getClientes(filtros: any): Observable<any> {
    return this.http.get("cliente", {
      params: filtros,
    });
  }

  getGetTokenRastreio(filtros: any): Observable<any> {
    return this.http.get("cliente/metodos/gettokenrastreio", {
      params: filtros,
    });
  }

  getInformativo(filtros: any): Observable<any> {
    return this.http.get("cliente/metodos/informativo", {
      params: filtros,
    });
  }

  getTrocaOleoInformativo(filtros: any): Observable<any> {
    return this.http.get("cliente/metodos/trocaoleoinformativo", {
      params: filtros,
    });
  }

  getTrocaFiltroInformativo(filtros: any): Observable<any> {
    return this.http.get("cliente/metodos/trocafiltroinformativo", {
      params: filtros,
    });
  }

  getContaOleoInformativo(filtros: any): Observable<any> {
    return this.http.get("cliente/metodos/contaoleoinformativo", {
      params: filtros,
    });
  }

  getContaFiltroInformativo(filtros: any): Observable<any> {
    return this.http.get("cliente/metodos/contafiltroinformativo", {
      params: filtros,
    });
  }

  getVencCnhInformativo(filtros: any): Observable<any> {
    return this.http.get("cliente/metodos/venccnhinformativo", {
      params: filtros,
    });
  }

  getVencIpvaInformativo(filtros: any): Observable<any> {
    return this.http.get("cliente/metodos/vencipvainformativo", {
      params: filtros,
    });
  }

  getRamoAtividade(): Observable<any> {
    return this.http.get("cliente/metodos/ramoatividade");
  }

  getEmpresaRastreio(): Observable<any> {
    return this.http.get("cliente/metodos/rastreio");
  }

  getSegmentos(): Observable<any> {
    return this.http.get("cliente/metodos/segmentos");
  }

  getUltCodTN(): Observable<any> {
    return this.http.get("cliente/metodos/codtn");
  }

  attCliente(dados: any): Observable<any> {
    return this.http.put("cliente", {
      cliente: dados,
    });
  }

  addCliente(dados: any): Observable<any> {
    return this.http.post("cliente", {
      cliente: dados,
    });
  }

  getClienteCnpj(filtros: any): Observable<any> {
    return this.http.get("cliente/metodos/getclientecnpj", {
      params: filtros,
    });
  }

  getComboClientes(): Observable<any> {
    return this.http.get("cliente/metodos/getComboClientes");
  }

  getComboClientesParam(filtros: any): Observable<any> {
    return this.http.get("cliente/metodo/getComboClientesParam", {
      params: filtros,
    });
  }

  getProtheusVendedores(filtros: any): Observable<any> {
    return this.http.get("cliente/metodos/getProtheusVendedores", {
      params: filtros,
    });
  }

  getProtheusParceiros(filtros: any): Observable<any> {
    return this.http.get("cliente/metodos/getProtheusParceiros", {
      params: filtros,
    });
  }

  getProtheusModelos(filtros: any): Observable<any> {
    return this.http.get("cliente/metodos/getProtheusModelos", {
      params: filtros,
    });
  }

  getClienteCodtn(filtros: any): Observable<any> {
    return this.http.get("cliente/metodos/getclientebycodtn", {
      params: filtros,
    });
  }

  getNovoLogin(filtros: any): Observable<any> {
    return this.http.get("cliente/metodos/getnovologin", {
      params: filtros,
    });
  }

  getSaldoAtual(filtros: any): Observable<any> {
    return this.http.get("cliente/metodos/getsaldoatual", {
      params: filtros,
    });
  }

  getEstadosCivis(): Observable<any> {
    return this.http.get("cliente/estadoCivel");
  }

  getGastoAtual(filtros: any): Observable<any> {
    return this.http.get("cliente/metodos/getgastoatual", {
      params: filtros,
    });
  }

  attClienteCertificado(dados: any): Observable<any> {
    return this.http.put("cliente/certificado", {
      cliente: dados,
    });
  }

  getModulos(): Observable<any> {
    return this.http.get("cliente/metodos/modulos");
  }

  getClientesRelacionados(idcliente: number): Observable<any> {
    return this.http.get("cliente/metodos/getClientesRelacionados", {
      params: { idcliente },
    });
  }
}
