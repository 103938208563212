import { Observable } from 'rxjs';
import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';

@Injectable()
export class EmpresaService {
  constructor(private http: HttpClient) {}

  get(): Observable<any> {
    return this.http.get('empresa');
  }

  getSubredes(filtros: any): Observable<any> {
    return this.http.get('subredes', {
      params: filtros,
    });
  }

  verificarCNPJ(filtros: any): Observable<any> {
    return this.http.get('subredes/verificacnpj', {
      params: filtros,
    });
  }

  addSubrede(dados: any): Observable<any> {
    return this.http.post('subredes', dados);
  }

  editSubrede(dados: any): Observable<any> {
    return this.http.put('subredes', dados);
  }
}
