import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'filterProdutos'
})
export class FilterProdutosPipe implements PipeTransform {
  transform(items: any[], tipoProduto: string): any {
    return items.filter(item => (item.idtipoproduto == tipoProduto));
  }
}
