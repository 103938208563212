import { Component } from "@angular/core";
import { Router } from "@angular/router";
import { UserService } from "../../modulos/shared/services/user.service";
import { ClienteService } from "../../modulos/clientes/cliente.service";
import { CredenciadoService } from "../../modulos/credenciados/services/credenciado.service";
import { SidebarNotifyService } from "../../modulos/shared/services/sidebar-notify.service";

@Component({
  selector: "app-header",
  templateUrl: "./header.component.html",
  styleUrls: ["./header.component.scss"],
})
export class AppHeaderComponent {
  idempresaLogin = "";
  cliente: any;

  clientesCentralizadora = [];
  clienteSelecionado = 0;

  cliOriginalNome = "";
  cliOriginalCodigo = 0;

  nomeCC = "";

  temAbastecimento = false;
  temManutencao = false;
  temRastreamento = false;
  temNF = false;
  temVale = false;
  temComun = false;
  temCursos = false;

  tipoUsuario = 1;

  credsCentralizadora = [];
  credSelecionado = 0;

  credOriginalNome = "";
  credOriginalCodigo = 0;

  showMenu = false;

  constructor(
    private userService: UserService,
    private clienteService: ClienteService,
    private credenciadoService: CredenciadoService,
    private router: Router,
    private sidebarNotifyService: SidebarNotifyService
  ) {
    this.temAbastecimento = this.userService.temModulo(1);
    this.temManutencao = this.userService.temModulo(2);
    this.temRastreamento = this.userService.temModulo(3);
    this.temNF = this.userService.temModulo(6);
    this.temVale = this.userService.temModulo(7);
    this.temComun = this.userService.temModulo(8);
    this.temCursos = this.userService.temModulo(9);
    this.tipoUsuario = this.userService.tipoUsuario;

    this.nomeCC = this.userService.usuario.nomeCC;
    this.clientesCentralizadora = this.userService.clientesCentralizadora;

    this.credsCentralizadora = this.userService.credsCentralizadora;

    if (userService.clienteOriginal) {
      this.cliOriginalNome = this.userService.clienteOriginal.nome;
      this.cliOriginalCodigo = this.userService.clienteOriginal.codigo;
    } else {
      this.cliOriginalNome = userService.usuario.nome;
      this.cliOriginalCodigo = userService.usuario.idcliente;
    }

    if (userService.credOriginal) {
      this.credOriginalNome = this.userService.credOriginal.nome;
      this.credOriginalCodigo = this.userService.credOriginal.codigo;
    } else {
      this.credOriginalNome = userService.usuario.nome;
      this.credOriginalCodigo = userService.usuario.idcredenciado;
    }

    this.clienteSelecionado = userService.usuario.idcliente;

    this.credSelecionado = userService.usuario.idcredenciado;

    if (
      userService.usuario.idcliente != undefined &&
      userService.usuario.idcliente != 0 &&
      !clienteService.getCliente()
    ) {
      clienteService
        .getClienteHttp(userService.usuario.idcliente)
        .subscribe((response) => {
          if (response.success) {
            this.idempresaLogin = response.cliente.idempresa;
            this.cliente = response.cliente;
          }
        });
    } else if (
      userService.usuario.idcliente != undefined &&
      clienteService.getCliente() &&
      clienteService.getCliente().idcliente != ""
    ) {
      this.idempresaLogin = clienteService.getCliente().idempresa;
      this.cliente = clienteService.getCliente();
    }

    this.sidebarNotifyService.on().subscribe((data) => {
      if (data == "fechar") {
        this.showMenu = false;
      }
    });
  }

  logoff(): void {
    this.router.navigate(["logout"]);
  }

  trocarCliente(cli): void {
    this.clienteService
      .getNovoLogin({
        cliente: cli,
        nome: this.cliOriginalNome,
        codigo: this.cliOriginalCodigo,
      })
      .subscribe((response) => {
        this.userService.alterarLogin(response.token).subscribe((res) => {
          this.router.navigate(["/"]).then(() => {
            window.location.reload();
          });
        });
      });
  }

  trocarCred(cred): void {
    this.credenciadoService
      .getNovoLogin({
        cred: cred,
        nome: this.credOriginalNome,
        codigo: this.credOriginalCodigo,
      })
      .subscribe((response) => {
        this.userService.alterarLoginCred(response.token).subscribe((res) => {
          this.router.navigate(["/"]).then(() => {
            window.location.reload();
          });
        });
      });
  }

  abrirMenu() {
    if (!this.showMenu) {
      this.showMenu = true;
      this.sidebarNotifyService.fire("abrir");
    } else {
      this.sidebarNotifyService.fire("fechar");
    }
  }
}
