import { Component, Input, AfterViewInit } from '@angular/core';

@Component({
  selector: 'configuracoes-veiculo',
  templateUrl: './configuracoes-veiculo.component.html',
  styleUrls: ['./../../visualizar-veiculos.component.css'],
})
export class ConfiguracoesVeiculoComponent implements AfterViewInit {
  @Input('sistema') sistema: number;
  @Input('arrVeiculos') arrVeiculos: any;
  @Input('arrCartaoVeiculos') arrCartaoVeiculos: any;
  @Input('arrHistoricoVeiculos') arrHistoricoVeiculos: any[] = [];
  @Input('arrCartaoVeiculosManutencao') arrCartaoVeiculosManutencao: any;

  constructor() {}

  ngAfterViewInit(): void {}
}
