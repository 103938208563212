import { Component, EventEmitter, Input, Output } from '@angular/core';

@Component({
  selector: 'app-base-report',
  templateUrl: './base-report.component.html',
  styleUrls: ['./base-report.component.scss']
})
export class BaseReportComponent {
  visibleFilters: boolean = false;
  filters: any;
  
  @Input() reportData: any;
  @Input() reportPath: string = "";
  @Input() contentLoaded: boolean = false;
  @Input() loadedReport: boolean = false;
  @Input() hasFilters: boolean = true;

  @Output() onGoBack = new EventEmitter<void>();
  @Output() onQueryData = new EventEmitter<void>();
  


  goback(){
    this.onGoBack.emit();
  }

  queryData(){
    this.visibleFilters = false;
    this.onQueryData.emit();
  }
}
