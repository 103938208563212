import { NgModule } from "@angular/core";
import { Routes, RouterModule, PreloadAllModules } from "@angular/router";
import { AuthLogin } from "./auth-login.service";
import { LogoutComponent } from "./logout.component";
import { AuthGuard } from "./auth-guard.service";
import { BaseComponent } from "./template/base/base.component";

const routes: Routes = [
  {
    path: "login",
    loadChildren: () =>
      import("./modulos/login/login.module").then((m) => m.LoginModule),
    canActivate: [AuthLogin],
  },
  {
    path: "",
    component: BaseComponent,
    canActivate: [AuthGuard],
    children: [
      {
        path: "home",
        loadChildren: () =>
          import("./modulos/home/home.module").then((m) => m.HomeModule),
      },
      {
        path: "abastecimento",
        loadChildren: () =>
          import("./modulos/abastecimento/abastecimento.module").then(
            (m) => m.AbastecimentoModule
          ),
      },
      {
        path: "manutencao",
        loadChildren: () =>
          import("./modulos/manutencao/manutencao.module").then(
            (m) => m.ManutencaoModule
          ),
      },
      {
        path: "rastreamento",
        loadChildren: () =>
          import("./modulos/rastreamento/rastreamento.module").then(
            (m) => m.RastreamentoModule
          ),
      },
      {
        path: "notafiscal",
        loadChildren: () =>
          import("./modulos/notafiscal/notafiscal.module").then(
            (m) => m.NotafiscalModule
          ),
      },
      {
        path: "valepedagio",
        loadChildren: () =>
          import("./modulos/valepedagio/valepedagio.module").then(
            (m) => m.ValepedagioModule
          ),
      },
      {
        path: "comunicacao",
        loadChildren: () =>
          import("./modulos/comunicacao/comunicacao.module").then(
            (m) => m.ComunicacaoModule
          ),
      },
      {
        path: "cursos",
        loadChildren: () =>
          import("./modulos/cursos/cursos.module").then((m) => m.CursosModule),
      },
      {
        path: "parceiros",
        loadChildren: () =>
          import("./modulos/parceiros/parceiros.module").then(
            (m) => m.ParceirosModule
          ),
      },
      {
        path: "clientes",
        loadChildren: () =>
          import("./modulos/clientes/clientes.module").then(
            (m) => m.ClientesModule
          ),
      },
      {
        path: "credenciados",
        loadChildren: () =>
          import("./modulos/credenciados/credenciados.module").then(
            (m) => m.CredenciadosModule
          ),
      },
      {
        path: "veiculos",
        loadChildren: () =>
          import("./modulos/veiculos/veiculos.module").then(
            (m) => m.VeiculosModule
          ),
      },
      {
        path: "cartoes",
        loadChildren: () =>
          import("./modulos/cartoes/cartoes.module").then(
            (m) => m.CartoesModule
          ),
      },
      {
        path: "implantacoes",
        loadChildren: () =>
          import("./modulos/implantacoes/implantacoes.module").then(
            (m) => m.ImplantacoesModule
          ),
      },
      {
        path: "monitoramento",
        loadChildren: () =>
          import("./modulos/monitoramento/monitoramento.module").then(
            (m) => m.MonitoramentoModule
          ),
      },
      {
        path: "mapa",
        loadChildren: () =>
          import("./modulos/mapa/mapa.module").then((m) => m.MapaModule),
      },
      {
        path: "comunicacao",
        loadChildren: () =>
          import("./modulos/comunicacao/comunicacao.module").then(
            (m) => m.ComunicacaoModule
          ),
      },
      {
        path: "configuracoes",
        loadChildren: () =>
          import("./modulos/configuracoes/configuracoes.module").then(
            (m) => m.ConfiguracoesModule
          ),
      },
      {
        path: "parametros",
        loadChildren: () =>
          import("./modulos/parametros/parametros.module").then(
            (m) => m.ParametrosModule
          ),
      },
      {
        path: "conciliacaogood",
        loadChildren: () =>
          import("./modulos/conciliacaogood/conciliacaogood.module").then(
            (m) => m.ConciliacaoGoodModule
          ),
      },
      {
        path: "empresa",
        loadChildren: () =>
          import("./modulos/empresa/empresa.module").then(
            (m) => m.EmpresaModule
          ),
      },
      {
        path: "procedimentovenda",
        loadChildren: () =>
          import("./modulos/procedimento-venda/procedimento-venda.module").then(
            (m) => m.ProcedimentoVendaModule
          ),
      },
      {
        path: "estabelecimento",
        loadChildren: () =>
          import("./modulos/estabelecimento/estabelecimento.module").then(
            (m) => m.EstabelecimentoModule
          ),
      },
      {
        path: "realizarvenda",
        loadChildren: () =>
          import("./modulos/transacao-web/transacao-web.module").then(
            (m) => m.TransacaoWebModule
          ),
      },
      {
        path: "parceiro",
        loadChildren: () =>
          import("./modulos/parceiro/parceiro.module").then(
            (m) => m.ParceiroModule
          ),
      },
      { path: "", redirectTo: "home", pathMatch: "full" },
    ],
  },
  { path: "logout", component: LogoutComponent },
  { path: "**", redirectTo: "login", pathMatch: "full" },
];

@NgModule({
  imports: [
    RouterModule.forRoot(routes, {
      preloadingStrategy: PreloadAllModules,
    }),
  ],
  exports: [RouterModule],
})
export class AppRoutingModule {}
