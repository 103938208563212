import {
  Component,
  Input,
  OnChanges,
  SimpleChange,
  Output,
  EventEmitter,
  OnInit,
  ViewChild,
  ElementRef,
} from "@angular/core";
import { UsuariosService } from "../../../services/usuarios.service";
import { UserService } from "../../../services/user.service";
import Swal from "sweetalert2";
import { ClienteService } from "src/app/modulos/clientes/cliente.service";

@Component({
  selector: "pesquisar-usuarios",
  templateUrl: "./pesquisar-usuarios.component.html",
})
export class PesquisarUsuariosComponent implements OnChanges, OnInit {
  @Input("evento") evento: any;
  @Input("tipo") type: number;
  @Input("idrelacionado") idrelated: number;

  filter = {
    nome: "",
    cgc: "",
    paginaAtual: 1,
    paginaFinal: 1,
    quantidade: 0,
    limite: "10",
    ordenacao: "ua.nome",
    sorting: "asc",
    tipo: 0,
    idrelacionado: 0,
    perfis: [],
    statusAtivo: true,
    statusBloqueado: true,
    statusCancelado: true,
    hideCgc: "03424004790",
  };
  arrUsers: any = [];
  arrProfiles: any = [];
  ordering = [
    { estado: 1, campo: "ua.nome" },
    { estado: 2, campo: "u.cgc" },
    { estado: 3, campo: "p.nome" },
    { estado: 4, campo: "ua.status" },
  ];
  contentLoaded = false;
  selectedAll = false;

  @Output("carregado") loaded = new EventEmitter<boolean>();
  @Output("editar") onUpdateUser = new EventEmitter<string>();
  @Output("visualizar") onViewUser = new EventEmitter<string>();

  filtersVisible: boolean = false;

  canUpdate = true;
  userType = 0;

  constructor(
    private usuariosService: UsuariosService,
    private clienteService: ClienteService,
    private userService: UserService
  ) {
    this.loaded.emit(false);

    this.canUpdate = this.userService.alterarDados;
    this.userType = this.userService.tipoUsuario;
  }

  clearFilters() {
    this.filter.nome = "";
    this.filter.cgc = "";
    this.filter.statusAtivo = true;
    this.filter.statusBloqueado = true;
    this.filter.statusCancelado = true;
  }

  ngOnChanges(changes: { [propKey: string]: SimpleChange }) {
    this.filter.tipo = this.type;
    this.filter.idrelacionado = this.idrelated;
    this.filterDataGridLocal(this.filter);
  }

  ngOnInit() {
    this.filter.tipo = this.type;
    this.filter.idrelacionado = this.idrelated;
  }

  updateCheckedOptions(option: any, event: any) {
    let index = this.filter.perfis.indexOf(option);

    if (event.checked && index <= -1) {
      this.filter.perfis.push(option);
    } else if (index > -1) {
      this.filter.perfis.splice(index, 1);
    }
  }

  onUpdate(id: string): void {
    this.onUpdateUser.emit(id);
  }

  onView(id: string): void {
    this.onViewUser.emit(id);
  }

  sortColun(posicao: any): any {
    this.ordering[posicao].estado =
      this.ordering[posicao].estado == 2
        ? 1
        : this.ordering[posicao].estado == 1
        ? 0
        : 1;
    this.filter.ordenacao = this.ordering[posicao].campo;

    for (let x = 0; x < this.ordering.length; x++) {
      if (posicao != x) {
        this.ordering[x].estado = 2;
      }
    }

    this.filter.sorting = this.ordering[posicao].estado == 0 ? "DESC" : "ASC";
    this.filterDataGridLocal(this.filter);
  }

  getClassHeadTable(id: number): string {
    if (this.ordering[id].estado == 2) {
      return "sorting";
    } else if (this.ordering[id].estado == 0) {
      return "sorting_desc";
    }

    return "sorting_asc";
  }

  filterDataGridLocal(filtros: any): any {
    this.loaded.emit(false);
    this.contentLoaded = false;

    if (this.filtersVisible) {
      this.filtersVisible = false;
    }

    let filter: any = Object.assign({}, filtros);
    filter.perfis = JSON.stringify(filter.perfis);

    this.usuariosService.getUsuario(filter).subscribe((response) => {
      if (response.success) {
        this.arrUsers = response.dados.map((usuario) => ({
          ...usuario,
          selected: false,
        }));
        this.selectedAll = false;
        this.filter.paginaFinal = response.paginaFinal;
        this.filter.quantidade = response.quantidade;

        if (this.filter.paginaFinal < this.filter.paginaAtual) {
          this.filter.paginaAtual = 1;
        }

        if (response.perfis && this.arrProfiles.length == 0) {
          this.arrProfiles = response.perfis;
        }

        if (response.perfis && this.filter.perfis.length == 0) {
          for (let i = 0; i < response.perfis.length; i++) {
            this.filter.perfis.push(response.perfis[i].id);
          }
        }
      }

      this.loaded.emit(true);
      this.contentLoaded = true;
    });
  }

  pageChange(page: number): any {
    this.filter.paginaAtual = page;
    this.filterDataGridLocal(this.filter);
  }

  markAll() {
    this.selectedAll = !this.selectedAll;
    this.arrUsers.forEach((element) => {
      if (element.status === 1) {
        element.selected = this.selectedAll;
      }
    });
  }

  blockInBatch() {
    let arrUsersToBlock = this.arrUsers.filter(
      (motorista) => motorista.selected
    );

    if (arrUsersToBlock.length === 0) {
      Swal.fire(
        "Erro",
        "Selecione ao menos um motorista para bloquear",
        "error"
      );
      return;
    }

    let ids = arrUsersToBlock.map((motorista) => motorista.id);

    Swal.fire({
      title: "Deseja bloquear os usuários selecionados?",
      icon: "warning",
      showCancelButton: true,
      confirmButtonText: "Sim",
      cancelButtonText: "Não",
      showLoaderOnConfirm: true,
      preConfirm: () => {
        return this.userService.bloquearUsuarios({
          ids: ids,
          idcliente: this.clienteService.getCliente().idcliente,
        });
      },
    }).then((result) => {
      if (result.isConfirmed) {
        if (result.value.success) {
          Swal.fire("Sucesso", "Usuários bloqueados com sucesso", "success");
          this.filterDataGridLocal(this.filter);
        }
      }
    });
  }
}
