import { ROTAS_CONSULTAS_CRED } from "./../../rotas-consulta-cred.enum";
import { Subscription } from "rxjs";
import { Component, OnDestroy, OnInit } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { CredenciadoService } from "src/app/modulos/credenciados/services/credenciado.service";
import { UserService } from "src/app/modulos/shared/services/user.service";
import Swal from "sweetalert2";

@Component({
  selector: "app-selecionar",
  templateUrl: "./selecionar.component.html",
})
export class SelecionarComponent implements OnInit, OnDestroy {
  ComboTipoConsulta = "";

  idcred: number;

  subscription = new Subscription();

  modulo: number;

  ROTAS_CONSULTAS_CRED = ROTAS_CONSULTAS_CRED;

  constructor(
    private credenciadoService: CredenciadoService,
    private userService: UserService,
    private router: Router,
    private route: ActivatedRoute
  ) {}

  ngOnInit(): any {
    this.subscription.add(
      this.route.data.subscribe(({ modulo }) => {
        if (modulo) {
          this.modulo = modulo;
        }
      })
    );

    this.subscription.add(
      this.route.params.subscribe(({ id }) => {
        if (id) {
          this.idcred = id;
        } else {
          this.idcred = this.userService.usuario.idcredenciado;
        }
      })
    );

    this.selecionarCredenciado();
  }

  selecionarCredenciado(): void {
    this.credenciadoService
      .getCredenciadoInfo({ idcred: this.idcred })
      .subscribe({
        next: (response) => {
          if (response.success) {
            this.credenciadoService.setCredenciadoRelatorios(
              response.credenciado,
              this.modulo
            );
          }
        },
      });
  }

  gerarConsulta(tipoconsulta: any): void {
    this.router.navigate([tipoconsulta], { relativeTo: this.route });
  }

  ngOnDestroy(): void {
    this.subscription && this.subscription.unsubscribe();
  }
}
