import { Component, ViewChild, ElementRef, OnInit } from "@angular/core";
import { PublicoService } from "../../services/publico.service";

@Component({
  selector: "desempenho",
  templateUrl: "./desempenho.component.html",
})
export class DesempenhoComponent implements OnInit {
  carregado = false;

  anos = [];

  filtro = {
    ano: "",
    placa: "",
    centroDeCusto: "",
  };

  filtroDesempenho = {
    ano: "",
    placa: "",
    centroDeCusto: "",
  };

  @ViewChild("anoVar") anoVar: ElementRef;

  constructor(private publicoService: PublicoService) {}

  ngOnInit(): void {
    this.publicoService.getData().subscribe((response) => {
      this.filtro.ano = response.ano;

      this.anos.push(response.ano);
      this.anos.push((response.ano - 1).toString());

      this.aplicarFiltros();

      this.carregado = true;
    });
  }

  aplicarFiltros() {
    this.filtroDesempenho = JSON.parse(JSON.stringify(this.filtro));
  }
}
