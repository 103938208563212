import { Component, OnInit } from "@angular/core";
import { RegraService } from "../regras.service";
import { ClienteService } from "src/app/modulos/clientes/cliente.service";

@Component({
  selector: "app-log-alteracoes-regras",
  templateUrl: "./log-alteracoes-regras.component.html",
})
export class LogAlteracoesRegrasComponent implements OnInit {
  arrDados: any[] = [];
  camposRegras = {
    produtos2: { label: "Regras de Produtos" },
    localidade: { label: "Regras de Localização" },
    credenciados: { label: "Regras de Rede Por Credenciado" },
    restricoes: { label: "Regras de Restrições" },
  };
  cliente: any = {};
  exclusiveFields = [];

  constructor(
    private regraService: RegraService,
    private clienteService: ClienteService
  ) {}

  ngOnInit() {
    this.cliente = this.clienteService.getCliente();
    this.regraService
      .getLogsRegras({ idcliente: this.cliente.idcliente })
      .subscribe((res) => {
        this.arrDados = res.dados;
      });
  }

  doIsNaN(variable): boolean {
    return (
      isNaN(variable) || variable == "" || !variable.toString().includes(".")
    );
  }
}
