// Imports
var ___CSS_LOADER_API_SOURCEMAP_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/sourceMaps.js");
var ___CSS_LOADER_API_IMPORT___ = require("../../../../../../../node_modules/css-loader/dist/runtime/api.js");
var ___CSS_LOADER_EXPORT___ = ___CSS_LOADER_API_IMPORT___(___CSS_LOADER_API_SOURCEMAP_IMPORT___);
// Module
___CSS_LOADER_EXPORT___.push([module.id, `
select:not([class*="col-"]):not([class*="form-control"]):not(.input-group-btn) {
  width: 100% !important;
}

.card.sub {
  box-shadow: none;
}
`, "",{"version":3,"sources":["webpack://./src/app/modulos/shared/core/informativo/informativo-rede/informativo.redes.component.css"],"names":[],"mappings":";AACA;EACE,sBAAsB;AACxB;;AAEA;EACE,gBAAgB;AAClB","sourcesContent":["\nselect:not([class*=\"col-\"]):not([class*=\"form-control\"]):not(.input-group-btn) {\n  width: 100% !important;\n}\n\n.card.sub {\n  box-shadow: none;\n}\n"],"sourceRoot":""}]);
// Exports
module.exports = ___CSS_LOADER_EXPORT___.toString();
