import { Pipe, PipeTransform } from "@angular/core";

@Pipe({
  name: "filtrarProdutosCliente",
})
export class ProdutosPipe implements PipeTransform {
  transform(items: any[], args: any): any {
    return items.filter((item: any, key: any) => {
      if (item.idtipoproduto == args) {
        return item;
      }

      if (item.tipoproduto == args) {
        return item;
      }
    });
  }
}
