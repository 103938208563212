import { Component, EventEmitter, Input, OnInit, Output } from '@angular/core';
import { UntypedFormControl, Validators } from '@angular/forms';
import { Observable } from 'rxjs';

@Component({
  selector: 'local-autocomplete',
  templateUrl: './local-autocomplete.component.html',
  styleUrls: ['./local-autocomplete.component.css'],
})
export class LocalAutocompleteComponent implements OnInit {
  filteredOptionsControl: Observable<any[]>;

  filteredValues: any[] = [];

  inputControl = new UntypedFormControl('', [Validators.required]);

  options: any[] = [];

  @Input() notFoundMessage: string;

  @Input() placeHolder: string;

  @Input() displayedKey: string;

  @Input() valueKey: string;

  @Input() label: string;

  @Input() inputClass: string;

  @Input() set setOptions(array: any[]) {
    this.options = array;
  }

  @Output('filteredValues') emitValues = new EventEmitter<any[]>();

  constructor() {}

  ngOnInit(): void {
    this.inputControlValueChanges();
  }

  inputControlValueChanges() {
    this.inputControl.valueChanges.subscribe({
      next: (value) => {
        this.filteredValues = [];
        const filterValue = value.toLowerCase();
        this.filteredValues.push(
          this.options?.filter((option) => {
            return option[this.valueKey].toLowerCase().includes(filterValue);
          })
        );
        this.emitValues.emit(this.filteredValues);
      },
    });
  }
}
