import { Component, Input, OnInit } from '@angular/core';
import { ClienteService } from 'src/app/modulos/clientes/cliente.service';

import { VeiculoService } from 'src/app/modulos/veiculos/veiculo.service';

@Component({
  selector: 'motoristas-grupos',
  templateUrl: './motoristas-grupos.component.html',
  styleUrls: ['./../../visualizar-veiculos.component.css'],
})
export class MotoristasGruposComponent implements OnInit {
  @Input('idveiculo') idveiculo: number;

  idcliente: string;
  arrMotorista: any[] = [];
  arrGrupoMotorista: any[] = [];
  conteudoCarregado = false;

  constructor(
    private veiculoService: VeiculoService,
    private clienteService: ClienteService
  ) { }

  ngOnInit(): void {
    this.idcliente = this.clienteService.idcliente;
    this.pegarMotoristasPorVeiculo();
  }

  pegarMotoristasPorVeiculo() {
    this.veiculoService
      .pegarMotoristasPorVeiculo({
        idveiculo: this.idveiculo,
        idcliente: this.idcliente,
      })
      .subscribe((response) => {
        if (response.success) {
          this.arrMotorista = response.veiculoMotoristas;
          this.arrGrupoMotorista = response.veiculoGrupoMotoristas;
        }

        this.conteudoCarregado = true;
      });
  }
}
