import {Pipe, PipeTransform} from '@angular/core';

@Pipe({
  name: 'toUpperCase'
})
export class UpperPipe implements PipeTransform {
  transform(value: string) {
    return value.toUpperCase();
  }
}
