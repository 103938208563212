import { Component, Input, OnInit } from '@angular/core';
import { Chart } from 'angular-highcharts';
import { DesempenhoService } from '../../../services/desempenho.service';

@Component({
  selector: 'line-char-data-ticketmedio',
  templateUrl: './line-char-data.html',
  styleUrls: ['./line-char-data.css'],
})
export class LineChartDataTicketMedioComponent implements OnInit {
  charta: any;

  lineChartColors = [
    'rgba(159,159,159,0.8)',
    'rgba(54,148,244,0.7)',
    'rgba(184,244,54,0.7)',
    'rgba(244,170,54,0.7)',
    'rgba(244,67,54,0.7)',
    'rgba(0,150,136,0.8)',
  ];

  graficoAtivo = false;

  constructor(private desempenhoService: DesempenhoService) { }

  ngOnInit(): void {
    this.defineFilterChanger();
  }

  defineFilterChanger(): void {
    this.desempenhoService
      .getInformativoRedeTicketMedio()
      .subscribe((response) => {
        response.ticketMedio = response.ticketMedio.map((item, ticketIndex) => {
          for (let monthIndex = 0; monthIndex < 12; monthIndex++) {
            if (!item.data[monthIndex]) {
              item.data[monthIndex] = 0;
            } else {
              item.data[monthIndex] *= 1;
            }

            item.name = item.label;
            item.color = this.lineChartColors[ticketIndex];
          }
          return item;
        });

        this.charta = new Chart({
          chart: {
            type: 'column',
            height: 300,
          },
          title: {
            text: 'Ticket médio por tipo de combustivel',
          },
          credits: {
            enabled: false,
          },
          series: response.ticketMedio,
          yAxis: {
            min: 0,
            title: {
              text: '',
            },
          },
          xAxis: {
            categories: [
              'Janeiro',
              'Fevereiro',
              'Março',
              'Abril',
              'Maio',
              'Junho',
              'Julho',
              'Agosto',
              'Setembro',
              'Outubro',
              'Novembro',
              'Dezembro',
            ],
            crosshair: true,
            labels: {
              x: -10,
            },
          },
          legend: {
            layout: 'vertical',
            align: 'left',
            verticalAlign: 'top',
            y: 40,
            itemStyle: {
              'font-size': '9px',
            },
          },
          plotOptions: {
            column: {
              pointPadding: 0.1,
              borderWidth: 0,
            },
          },
          responsive: {
            rules: [
              {
                condition: {
                  maxWidth: 500,
                },
                chartOptions: {
                  legend: {
                    enabled: false,
                  },
                },
              },
            ],
          },
          exporting: {
            enabled: false,
          },
        } as any);

        this.graficoAtivo = true;
      });
  }

  Popup() {
    this.charta.ref$.subscribe((ref) => {
      let svg = ref.getSVG();

      let mywindow = window.open('', 'my div', 'height=400,width=700');
      mywindow.document.write(
        '<html><head><title>&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;&nbsp;</title>'
      );
      mywindow.document.write('</head><body>');
      mywindow.document.write('' + svg + '');
      mywindow.document.write('</body></html>');

      mywindow.document.close(); // necessary for IE >= 10
      mywindow.focus(); // necessary for IE >= 10

      setTimeout(() => {
        mywindow.print();
        mywindow.close();
      }, 10);
    });
  }
}
