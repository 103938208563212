import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'formatTime',
})
export class TimePipe implements PipeTransform {
  transform(date: Date, args?: any): any {
    let data = new Date(date);
    let formatedDate: string;
    formatedDate = data.getHours().toString() + data.getMinutes().toString();
    return formatedDate;
  }
}
