import { Pipe, PipeTransform } from '@angular/core';

@Pipe({
  name: 'cartao',
})
export class CartaoPipe implements PipeTransform {
  transform(value: string, tipo: any) {
    if (value.length == 17) {
      if (tipo != 3) {
        return (
          value.substring(0, 4) +
          ' ' +
          value.substring(4, 8) +
          ' ' +
          value.substring(8, 12) +
          ' ' +
          value.substring(12, 16) +
          ' ' +
          value.substring(16)
        );
      }

      return (
        value.substring(0, 4) +
        ' ' +
        value.substring(4, 5) +
        '*** **** *' +
        value.substring(13, 16) +
        ' ' +
        value.substring(16)
      );
    }

    if (value.length == 16) {
      if (tipo != 3) {
        return (
          value.substring(0, 4) +
          ' ' +
          value.substring(4, 8) +
          ' ' +
          value.substring(8, 12) +
          ' ' +
          value.substring(12, 16)
        );
      }

      return (
        value.substring(0, 4) +
        ' ' +
        value.substring(4, 5) +
        '*** **** *' +
        value.substring(13, 16)
      );
    }

    return value;
  }
}
